import React, { Component } from "react";

// DOM COMPONENTS
import Share from "../../components/share";

// FUNCTIONAL COMPONENTS
import DrawCrosshair from "../../components/functional/drawCrosshair";

// STYLES
import "./canvas.scss";

// IMAGES
import darkBg from "../../images/kovaak-bg-dark.jpg";
import lightBg from "../../images/kovaak-bg-light.jpg";
import concreteBg from "../../images/kovaak-bg-concrete.jpg";

export default class CrosshairCanvas extends Component {
  constructor(props) {
    super();

    this.state = {
      activeBG: "dark",
      canvasBg: darkBg,
      // mousePos: undefined,
      mousePos: {x: 0, y: 0},
      windowDimensions: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
    };

    this.canvasRef = React.createRef();
    this.canvasDisplayRef = React.createRef();

    this.getMousePos = this.getMousePos.bind(this);
    this.updateCanvas = this.updateCanvas.bind(this);
    this.updateActiveCrosshairMenu = this.updateActiveCrosshairMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateCanvas);
    this.updateCanvas();
    const displayRef = this.canvasDisplayRef && this.canvasDisplayRef.current;
    this.setState({ mousePos: {x: displayRef.clientWidth/2, y: displayRef.clientHeight/2} })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCanvas);
  }

  componentDidUpdate() {
    this.updateCanvas();
  }

  render() {
    const { activeBG, canvasBg } = this.state;
    const { crosshairArray } = this.props;
    const top = this.state.mousePos.y;
    const left =  this.state.mousePos.x;
    return (
      <section className="crosshair__section wide">
        <h2 className="crosshair__title">Your Crosshair</h2>

      <div 
        ref={this.canvasDisplayRef}
        className="crosshair__display" style={{ backgroundImage: `url(${canvasBg})` }}
        onMouseMove={(e) => this.setState({ mousePos: this.getMousePos(e) })}
        onMouseLeave={() => {
          const displayRef = this.canvasDisplayRef && this.canvasDisplayRef.current;
          this.setState({ mousePos: {x: displayRef.clientWidth/2, y: displayRef.clientHeight/2} });
        }}>
        <canvas
          className="crosshair__reticle"
          style={{top, left}}
          ref={this.canvasRef}
        />
      </div>

        {/* Background Image Change */}

        <ul className="imageChange">
          <li
            className={`imageChange__option ${activeBG === "dark" ? "active" : ""}`}
            onClick={() => this.setState({ activeBG: "dark", canvasBg: darkBg })}
          >
            <img className="imageChange__image" alt="dark background" src={darkBg} />
            <p className="imageChange__bgName">Dark</p>
          </li>
          <li
            className={`imageChange__option ${activeBG === "light" ? "active" : ""}`}
            onClick={() => this.setState({ activeBG: "light", canvasBg: lightBg })}
          >
            <img className="imageChange__image" alt="light background" src={lightBg} />
            <p className="imageChange__bgName">Light</p>
          </li>
          <li
            className={`imageChange__option ${activeBG === "concrete" ? "active" : ""}`}
            onClick={() => this.setState({ activeBG: "concrete", canvasBg: concreteBg })}
          >
            <img className="imageChange__image" alt="concrete background" src={concreteBg} />
            <p className="imageChange__bgName">Concrete</p>
          </li>
        </ul>

        {crosshairArray.length > 0 && (
          <Share crosshairArray={crosshairArray} download={() => this.downloadImg()} />
        )}
      </section>
    );
  }

  updateCanvas(e) {
    const { crosshairArray } = this.props;
    const canvas = this.canvasRef.current;
    const displayRef = this.canvasDisplayRef && this.canvasDisplayRef.current;
    if (e && e.type === "resize") {
      this.setState({ mousePos: {x: displayRef.clientWidth/2, y: displayRef.clientHeight/2} })
    }
  
    if (canvas) {
      const ctx = canvas.getContext("2d");

      // Clear old content
      ctx.clearRect(0, 0, 500, 500);

      ctx.canvas.width = 500;
      ctx.canvas.height = 500;

      // reverse the draw array so that the #1 crosshair is drawn last.
      const drawArray = [...crosshairArray].reverse();

      // draw all crosshairs from array
      drawArray.forEach((crosshair) => DrawCrosshair(ctx, canvas, crosshair, {x: 250, y: 250}));
    }
  }

  getMousePos(evt) {
    let rect = (this.canvasDisplayRef && this.canvasDisplayRef.current && this.canvasDisplayRef.current.getBoundingClientRect()) || {left: 0, top: 0};
    const x = evt.clientX - rect.left;
    const y = evt.clientY - rect.top;

    return {
      x,
      y
    };
  }

  updateActiveCrosshairMenu(id) {
    const { activeControl } = this.state;
    this.setState({ activeControl: id === activeControl ? null : id });
  }

  downloadImg() {
    // Remove whitespace
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext("2d");
    const copy = document.createElement("canvas").getContext("2d");

    let pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let pixelLength = pixels.data.length;
    let bound = {
      top: null,
      left: null,
      bottom: null,
      right: null,
    };
    // Get the extents of any lit pixels
    let x, y;
    for (let i = 0; i < pixelLength; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % canvas.width;
        y = ~~(i / 4 / canvas.width);

        if (bound.top === null) {
          bound.top = y;
        }

        if (bound.left === null) {
          bound.left = x;
        } else if (x < bound.left) {
          bound.left = x;
        }

        if (bound.right === null) {
          bound.right = x;
        } else if (bound.right < x) {
          bound.right = x;
        }

        if (bound.bottom === null) {
          bound.bottom = y;
        } else if (bound.bottom < y) {
          bound.bottom = y;
        }
      }
    }
    const canvasCenterX = ctx.canvas.clientWidth / 2;
    const canvasCenterY = ctx.canvas.clientHeight / 2;
    if (bound.left > canvasCenterX) {
      bound.left = canvasCenterX - (bound.right - canvasCenterX);
    }
    if (bound.top > canvasCenterY) {
      bound.top = canvasCenterY - (bound.bottom - canvasCenterY);
    }
    if (bound.right < canvasCenterX) {
      bound.right = canvasCenterX + (canvasCenterX - bound.left);
    }
    if (bound.bottom < canvasCenterY) {
      bound.bottom = canvasCenterY + (canvasCenterY - bound.bottom)
    }
    const leftWidth = canvasCenterX - bound.left;
    const rightWidth = bound.right - canvasCenterX + 1;
    const upperWidth = canvasCenterY - bound.top;
    const lowerWidth = bound.bottom - canvasCenterY + 1;
    if (leftWidth > rightWidth) {
      bound.right = canvasCenterX + leftWidth;
    } else if (rightWidth > leftWidth) {
      bound.left = canvasCenterX - rightWidth;
    }
    if (upperWidth > lowerWidth) {
      bound.bottom = canvasCenterY + upperWidth;
    } else if (lowerWidth > upperWidth) {
      bound.top = canvasCenterY - lowerWidth;
    }

    const trimWidth = bound.right - bound.left + 1;
    const trimHeight = bound.bottom - bound.top + 1;
    let trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    copy.canvas.width = trimWidth;
    copy.canvas.height = trimHeight;
    copy.putImageData(trimmed, 0, 0);

    // download
    const link = document.createElement("a");
    link.download = "KovaaK-Crosshair.png";
    link.href = copy.canvas.toDataURL("image/png");
    link.click();
  }
}
