// FUNCTIONAL COMPONENTS
import Crosshair from "./crosshair";

// DATA CONFIGS
import Configurations from "../../configurations/crosshairShapesConfig.json";

function createShareURL(crosshairArray) {
  // Examples Share URL
  // http://localhost:3000/?sh=crosshair,sz=2,rt=45,sp=19,ln=13,cl=255|255|255|100&sh=circle,sz=2,cl=255|255|255|100&sh=square,sz=26,rt=45,cl=255|255|255|0,skw=1,skc=255|255|255|100

  const origin = window.parent ? window.parent.location.origin : window.location.origin;
  const pathName = window.location.pathname;

  let searchURL = crosshairArray.map((crosshair) => {
    // console.log(crosshair);
    const config = Configurations.shapes[crosshair.shape];
    const {
      shape,
      size,
      width,
      fillColor,
      fillAlpha,
      useGradient,
      gradientStartAlpha,
      gradientStartColor,
      gradientEndAlpha,
      gradientEndColor,
      useStrokeGradient,
      strokeGradientStartAlpha,
      strokeGradientStartColor,
      strokeGradientEndAlpha,
      strokeGradientEndColor,
      rotation,
      posY,
      curve,
      spacing,
      length,
      strokeWidth,
      strokeColor,
      strokeAlpha,
    } = crosshair;
    let stringArray = [`sh=${encodeURIComponent(shape)}`];

    // Set various attributes if the shape/style allows for them.
    if (config.hasSize) stringArray.push(`sz=${size}`);
    if (config.rotationAllowed) stringArray.push(`rt=${rotation}`);
    if (config.canEditPosY) stringArray.push(`py=${posY}`);
    if (config.hasSpacing) stringArray.push(`sp=${spacing}`);
    if (config.hasLength) stringArray.push(`ln=${length}`);
    if (config.hasCurve) stringArray.push(`cv=${curve}`);
    if (config.hasWidth) stringArray.push(`wd=${width}`);

    // Setup the fill color array
    if (config.canHaveFill) {
      if (useGradient) {
        stringArray.push(`gr=${useGradient},cls=${gradientStartColor._red}|${gradientStartColor._green}|${gradientStartColor._blue}|${gradientStartAlpha},cle=${gradientEndColor._red}|${gradientEndColor._green}|${gradientEndColor._blue}|${gradientEndAlpha}`);
      } else {
        stringArray.push(
          `cl=${fillColor._red}|${fillColor._green}|${fillColor._blue}|${fillAlpha}`
        );
      }
    }

    // Setup the stoke color array if the shape/style can have a stroke
    if (config.canHaveStroke && strokeWidth > 0) {
      if (useStrokeGradient) {
        stringArray.push(`skw=${strokeWidth},sgr=${useStrokeGradient},sgs=${strokeGradientStartColor._red}|${strokeGradientStartColor._green}|${strokeGradientStartColor._blue}|${strokeGradientStartAlpha},sge=${strokeGradientEndColor._red}|${strokeGradientEndColor._green}|${strokeGradientEndColor._blue}|${strokeGradientEndAlpha}`);
      } else {
        stringArray.push(
          `skw=${strokeWidth},skc=${strokeColor._red}|${strokeColor._green}|${strokeColor._blue}|${strokeAlpha}`
        );
      }
    }

    return stringArray.join(",");
  });

  return origin + pathName + "?" + searchURL.join("&");
}

function parseShareURL(urlString) {
  // Examples Share URL
  // http://localhost:3000/?sh=crosshair,sz=2,rt=45,sp=19,ln=13,cl=255|255|255|100&sh=circle,sz=2,cl=255|255|255|100&sh=square,sz=26,rt=45,cl=255|255|255|0,skw=1,skc=255|255|255|100

  const translation = {
    sh: "shape",
    sz: "size",
    wd: "width",
    rt: "rotation",
    py: "posY",
    sp: "spacing",
    cv: "curve",
    ln: "length",
    cl: "fillColor",
    gr: "useGradient",
    cls: "gradientStartColor",
    cle: "gradientEndColor",
    sgr: "useStrokeGradient",
    sgs: "strokeGradientStartColor",
    sge: "strokeGradientEndColor",
    skw: "strokeWidth",
    skc: "strokeColor",
  };

  const url = new URL(decodeURI(urlString));

  const parseArray = url.search
    .substr(1)
    .split("&")
    .map((arr) => arr.split(","));

  // Create a new crosshair and add the values from the shared url to the new crosshair.
  // This prevents unintended values from being used via the crosshair class' internal setter error handling.
  const crosshairArray = parseArray.map((arr) => {
    // create new Crosshair of the correct shape.
    const shapeParam = decodeURIComponent(arr.find((el) => decodeURIComponent(el).includes("sh")));
    const shape = shapeParam.substr(shapeParam.indexOf("=") + 1);
    let crosshair = new Crosshair({ shape });

    arr.forEach((param) => {
      const split = param.split("=");
      const key = translation[split[0]];
      const val = decodeURIComponent(split[1]);

      if (key.toLowerCase().includes("color")) {
        let alphaName;
        switch (key) {
          case "strokeColor":
            alphaName = "strokeAlpha";
            break;
          case "gradientStartColor":
            alphaName = "gradientStartAlpha";
            break;
          case "gradientEndColor":
            alphaName = "gradientEndAlpha";
            break;
          case "strokeGradientStartColor":
            alphaName = "strokeGradientStartAlpha";
            break;
          case "strokeGradientEndColor":
            alphaName = "strokeGradientEndAlpha";
            break;
          default:
            alphaName = "fillAlpha";
            break;
        }
        const colors = val.split("|");
        const red = parseInt(colors[0]);
        const green = parseInt(colors[1]);
        const blue = parseInt(colors[2]);
        const alpha = parseInt(colors[3]);

        crosshair[key] = {
          _red: red,
          _green: green,
          _blue: blue,
        };

        crosshair[alphaName] = alpha;
      } else if (val === "true" || val === "false") {
        // make sure booleans are set to booleans
        crosshair[key] = val === "true";
      } else if (/^-?[0-9]\d*$/.test(val)) {
        // If a value is only comprised of numerical values (most are) parse it as an int.
        crosshair[key] = parseInt(val);
      } else {
        crosshair[key] = val;
      }
    });

    return crosshair;
  });

  // return crosshair;
  return crosshairArray;
}

export { createShareURL, parseShareURL };
