import React from "react";

// === IMAGES
import weaponsMenu from "../../images/weapons-menu.png";
import crosshairMenu from "../../images/crosshair-menu.png";

// === STYLES
import "./instructions.scss";

function Instructions() {
  return (
    <article className="instructions">
      <h2 className="instructions__title">How do I install a crosshair in KovaaK's?</h2>
      <section className="instructions__step">
        <h3 className="instructions__heading">
          <span className="instructions__stepNumber">Step 1:</span>
          <span>Download</span>
        </h3>
        <p className="instructions__paragraph">
          Download your crosshair from the{" "}
          <span className="instructions__highlight">KovaaK's Crosshair Generator</span> site.
        </p>
      </section>
      <section className="instructions__step">
        <h3 className="instructions__heading">
          <span className="instructions__stepNumber">Step 2:</span>
          <span>File Location</span>
        </h3>
        <p className="instructions__paragraph">
          Give your crosshair a descriptive name and save it as a PNG file in your game's crosshairs
          folder.
        </p>
        <p className="instructions__paragraph">
          {" "}
          The default install location is{" "}
          <em>
            <code>
              "C:\Program Files (x86)\Steam\steamapps\common\FPSAimTrainer\FPSAimTrainer\crosshairs"
            </code>
          </em>
          . You can verify your specific install location by right clicking the game in your Steam
          library and going to <span className="instructions__highlight">Manage</span> &#x27A1;{" "}
          <span className="instructions__highlight">Browse Local Files</span>.
        </p>
      </section>
      <section className="instructions__step">
        <h3 className="instructions__heading">
          <span className="instructions__stepNumber">Step 3:</span>
          <span>In-Game</span>
        </h3>
        <p className="instructions__paragraph">
          In-game, go to Settings &#x27A1; Weapons, and click the crosshair image to bring up the
          Crosshair Selection pop-up. All image files that are in the above folder should show up as
          usable crosshairs, including the new one you just created.
        </p>
        <img className="instructions__image--large" src={weaponsMenu} alt="settings menu" />
        <p className="instructions__note">
          Note: Clicking the crosshair image to bring up the Crosshair Selection pop-up re-scans the
          crosshairs folder for new files, so you never need to restart the game to start using a
          new crosshair.
        </p>
        <img
          className="instructions__image--small"
          src={crosshairMenu}
          alt="crosshair selection menu"
        />
      </section>
    </article>
  );
}

export default Instructions;
