import React, { useState } from "react";

// DOM COMPONENTS
import { LightButton } from "../controls/buttons";

// FUNCTIONAL COMPONENTS
import { createShareURL } from "../functional/shareURL";

// STYLES
import "./share.scss";

function Share(props) {
  const { crosshairArray, download } = props;
  const [copyText, setCopyText] = useState("copy share link");

  function copyURLToClipboard() {
    const url = createShareURL(crosshairArray);
    navigator.clipboard.writeText(url);

    setCopyText("copied!");
    setTimeout(() => {
      setCopyText("copy share url");
    }, 1500);
  }

  return (
    <div className="share">
      <h3 className="share__title">Share Your Crosshair</h3>
      <div className="share__columns">
        <LightButton text="download crosshair png" onClick={download} />
        <LightButton text={copyText} onClick={copyURLToClipboard} />
      </div>
    </div>
  );
}

export default Share;
