import React from "react";
import "./examples.scss";

import Suggestions from "../../configurations/crosshairSuggestions.json";

function Examples(props) {
  const { setCrosshair } = props;
  const { suggestions } = Suggestions;
  const images = require.context("../../images", true);

  function getImagePath(name) {
    let path = images.keys().find((imgPath) => imgPath.includes(name));

    return path ? images(path) : "";
  }

  return (
    <div className="examples">
      <h3 className="examples__title">Popular Game Crosshairs</h3>
      <ul className="examples__list">
        {suggestions.map((example) => {
          const { name, image } = example;
          return (
            <li key={name} className="examples__listItem" onClick={() => setCrosshair(name)}>
              <img className="examples__img" alt={`${name} crosshair`} src={getImagePath(image)} />
              <p className="examples__name">{name}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Examples;
