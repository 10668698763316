// The Crosshair class is the center source of data for every crosshair designed in the app.
import Configurations from "../../configurations/crosshairShapesConfig.json";

export default class Crosshair {
  constructor(params = { shape: "crosshair" }) {
    // get default settings for shape. Will default to the shape provided in the parameter default above.
    const {
      fillAlpha,
      fillColor,
      useGradient,
      gradientStartAlpha,
      gradientStartColor,
      gradientEndAlpha,
      gradientEndColor,
      useStrokeGradient,
      strokeGradientStartAlpha,
      strokeGradientStartColor,
      strokeGradientEndAlpha,
      strokeGradientEndColor,
      size,
      width,
      curve,
      spacing,
      length,
      rotation,
      posY,
      strokeWidth,
      strokeAlpha,
      strokeColor,
    } = Configurations.shapes[params.shape].defaults;

    this._shape = params.shape;
    this._id = this.generateNewIdHash();

    this._fillAlpha = fillAlpha;
    this._fillColor = fillColor;

    this._useGradient = useGradient;
    this._gradientStartAlpha = gradientStartAlpha;
    this._gradientStartColor = gradientStartColor;
    this._gradientEndAlpha = gradientEndAlpha;
    this._gradientEndColor = gradientEndColor;

    this._useStrokeGradient = useStrokeGradient;
    this._strokeGradientStartAlpha = strokeGradientStartAlpha;
    this._strokeGradientStartColor = strokeGradientStartColor;
    this._strokeGradientEndAlpha = strokeGradientEndAlpha;
    this._strokeGradientEndColor = strokeGradientEndColor;

    this._size = size;
    this._width = width;
    this._curve = curve;
    this._spacing = spacing;
    this._length = length;
    this._rotation = rotation;
    this._posY = posY;

    this._strokeWidth = strokeWidth;
    this._strokeAlpha = strokeAlpha;
    this._strokeColor = strokeColor;
  }

  get shape() {
    return this._shape;
  }

  set shape(input) {
    const { shapes } = Configurations;
    const availableGeneratorShapes = Object.keys(shapes);
    try {
      if (typeof input !== "string") {
        throw new Error("Crosshair shape must be a string.");
      } else if (!availableGeneratorShapes.some((shape) => input === shape)) {
        throw new Error("Crosshair shape must be one of the available options.");
      } else {
        this._shape = input;
      }
    } catch (err) {
      console.warn(err);
      this._shape = "circle";
    }
  }

  // FILL COLOR & ALPHA

  get fillAlpha() {
    return this._fillAlpha;
  }

  set fillAlpha(input) {
    const resetValue = 100;

    try {
      if (typeof input !== "number" || input < 0 || input > 100) {
        throw new Error(
          `Fill Alpha input must be a number between 0 and 100. Resetting value to ${resetValue}.`
        );
      } else {
        this._fillAlpha = input;
      }
    } catch (err) {
      console.warn(err);
      this._fillAlpha = resetValue;
    }
  }

  get fillColor() {
    return this._fillColor;
  }

  set fillColor(input) {
    let minRGB = 0;
    let maxRGB = 255;

    try {
      if (typeof input !== "object" && !input.red && !input.green && !input.blue) {
        throw new Error("Fill color input must be an object with a red, green, and blue value.");
      } else if (input.red < minRGB || input.red > maxRGB) {
        throw new Error(`Red input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.green < minRGB || input.green > maxRGB) {
        throw new Error(`Green input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.blue < minRGB || input.blue > maxRGB) {
        throw new Error(`Blue input must be between ${minRGB} and ${maxRGB}.`);
      } else {
        const { _red, _green, _blue } = input;
        this._fillColor = {
          _red,
          _green,
          _blue,
        };
      }
    } catch (err) {
      console.warn(err);
      this._fillColor = {
        _red: 255,
        _green: 255,
        _blue: 255,
      };
    }
  }

  // STROKE VALUES & ALPHA

  get strokeWidth() {
    return this._strokeWidth;
  }

  set strokeWidth(input) {
    const config = Configurations.shapes[this._shape];
    const minStrokeWidth = config.minStrokeWidth || 0;
    const maxStrokeWidth = config.maxStrokeWidth || 10;
    const resetValue = 1;

    try {
      if (typeof input !== "number" || input > maxStrokeWidth || input < minStrokeWidth) {
        throw new Error(
          `Stroke Width must be a number between ${minStrokeWidth} and ${maxStrokeWidth}. Resetting value to ${resetValue}.`
        );
      } else {
        this._strokeWidth = input;
      }
    } catch (err) {
      console.warn(err);
      this._strokeWidth = resetValue;
    }
  }

  get strokeAlpha() {
    return this._strokeAlpha;
  }

  set strokeAlpha(input) {
    const minAlpha = 0;
    const maxAlpha = 100;
    const resetValue = 100;

    try {
      if (typeof input !== "number" || input > maxAlpha || input < minAlpha) {
        throw new Error(
          `Stroke Alpha input must be a number between ${minAlpha} and ${maxAlpha}. Resetting value to ${resetValue}.`
        );
      } else {
        this._strokeAlpha = input;
      }
    } catch (err) {
      console.warn(err);
      this._strokeAlpha = resetValue;
    }
  }

  get strokeColor() {
    return this._strokeColor;
  }

  set strokeColor(input) {
    let minRGB = 0;
    let maxRGB = 255;

    try {
      if (typeof input !== "object" && !input.red && !input.green && !input.blue) {
        throw new Error("Stroke color input must be an object with a red, green and blue value.");
      } else if (input.red < minRGB || input.red > maxRGB) {
        throw new Error(`Red input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.green < minRGB || input.green > maxRGB) {
        throw new Error(`Green input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.blue < minRGB || input.blue > maxRGB) {
        throw new Error(`Blue input must be between ${minRGB} and ${maxRGB}.`);
      } else {
        const { _red, _green, _blue } = input;
        this._strokeColor = {
          _red,
          _green,
          _blue,
        };
      }
    } catch (err) {
      console.warn(err);
      this._strokeColor = {
        _red: 255,
        _green: 255,
        _blue: 255,
      };
    }
  }

  // FILL GRADIENTS

  get useGradient() {
    return this._useGradient;
  }

  set useGradient(input) {
    try {
      if (typeof input !== "boolean") {
        throw new Error(`useGradient input must be a boolean value.`);
      } else {
        this._useGradient = input;
      }
    } catch (err) {
      console.warn(err);
      this._useGradient = false;
    }
  }

  get gradientStartAlpha() {
    return this._gradientStartAlpha;
  }

  set gradientStartAlpha(input) {
    const resetValue = 100;

    try {
      if (typeof input !== "number" || input < 0 || input > 100) {
        throw new Error(
          `Gradient Start Alpha input must be a number between 0 and 100. Resetting to ${resetValue}`
        );
      } else {
        this._gradientStartAlpha = input;
      }
    } catch (err) {
      console.warn(err);
      this._gradientStartAlpha = resetValue;
    }
  }

  get gradientStartColor() {
    return this._gradientStartColor;
  }

  set gradientStartColor(input) {
    let minRGB = 0;
    let maxRGB = 255;

    try {
      if (typeof input !== "object" && !input.red && !input.green && !input.blue) {
        throw new Error(
          "Gradient start color input must be an object with a red, green, and blue value."
        );
      } else if (input.red < minRGB || input.red > maxRGB) {
        throw new Error(`Red input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.green < minRGB || input.green > maxRGB) {
        throw new Error(`Green input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.blue < minRGB || input.blue > maxRGB) {
        throw new Error(`Blue input must be between ${minRGB} and ${maxRGB}.`);
      } else {
        const { _red, _green, _blue } = input;
        this._gradientStartColor = {
          _red,
          _green,
          _blue,
        };
      }
    } catch (err) {
      console.warn(err);
      this._gradientStartColor = {
        _red: 255,
        _green: 255,
        _blue: 255,
      };
    }
  }

  get gradientEndAlpha() {
    return this._gradientEndAlpha;
  }

  set gradientEndAlpha(input) {
    const resetValue = 100;

    try {
      if (typeof input !== "number" || input < 0 || input > 100) {
        throw new Error(
          `Gradient End Alpha input must be a number between 0 and 100. Resetting to ${resetValue}`
        );
      } else {
        this._gradientEndAlpha = input;
      }
    } catch (err) {
      console.warn(err);
      this._gradientEndAlpha = resetValue;
    }
  }

  get gradientEndColor() {
    return this._gradientEndColor;
  }

  set gradientEndColor(input) {
    let minRGB = 0;
    let maxRGB = 255;

    try {
      if (typeof input !== "object" && !input.red && !input.green && !input.blue) {
        throw new Error(
          "Gradient End color input must be an object with a red, green, and blue value."
        );
      } else if (input.red < minRGB || input.red > maxRGB) {
        throw new Error(`Red input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.green < minRGB || input.green > maxRGB) {
        throw new Error(`Green input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.blue < minRGB || input.blue > maxRGB) {
        throw new Error(`Blue input must be between ${minRGB} and ${maxRGB}.`);
      } else {
        const { _red, _green, _blue } = input;
        this._gradientEndColor = {
          _red,
          _green,
          _blue,
        };
      }
    } catch (err) {
      console.warn(err);
      this._gradientEndColor = {
        _red: 255,
        _green: 255,
        _blue: 255,
      };
    }
  }

  // STROKE GRADIENTS

  get useStrokeGradient() {
    return this._useStrokeGradient;
  }

  set useStrokeGradient(input) {
    try {
      if (typeof input !== "boolean") {
        throw new Error(`useStrokeGradient input must be a boolean value.`);
      } else {
        this._useStrokeGradient = input;
      }
    } catch (err) {
      console.warn(err);
      this._useStrokeGradient = false;
    }
  }

  get strokeGradientStartAlpha() {
    return this._strokeGradientStartAlpha;
  }

  set strokeGradientStartAlpha(input) {
    const resetValue = 100;

    try {
      if (typeof input !== "number" || input < 0 || input > 100) {
        throw new Error(
          `Stroke Gradient Start Alpha input must be a number between 0 and 100. Resetting to ${resetValue}`
        );
      } else {
        this._strokeGradientStartAlpha = input;
      }
    } catch (err) {
      console.warn(err);
      this._strokeGradientStartAlpha = resetValue;
    }
  }

  get strokeGradientStartColor() {
    return this._strokeGradientStartColor;
  }

  set strokeGradientStartColor(input) {
    let minRGB = 0;
    let maxRGB = 255;

    try {
      if (typeof input !== "object" && !input.red && !input.green && !input.blue) {
        throw new Error(
          "Gradient start color input must be an object with a red, green, and blue value."
        );
      } else if (input.red < minRGB || input.red > maxRGB) {
        throw new Error(`Red input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.green < minRGB || input.green > maxRGB) {
        throw new Error(`Green input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.blue < minRGB || input.blue > maxRGB) {
        throw new Error(`Blue input must be between ${minRGB} and ${maxRGB}.`);
      } else {
        const { _red, _green, _blue } = input;
        this._strokeGradientStartColor = {
          _red,
          _green,
          _blue,
        };
      }
    } catch (err) {
      console.warn(err);
      this._strokeGradientStartColor = {
        _red: 255,
        _green: 255,
        _blue: 255,
      };
    }
  }

  get strokeGradientEndAlpha() {
    return this._strokeGradientEndAlpha;
  }

  set strokeGradientEndAlpha(input) {
    const resetValue = 100;

    try {
      if (typeof input !== "number" || input < 0 || input > 100) {
        throw new Error(
          `Stroke Gradient End Alpha input must be a number between 0 and 100. Resetting to ${resetValue}`
        );
      } else {
        this._strokeGradientEndAlpha = input;
      }
    } catch (err) {
      console.warn(err);
      this._strokeGradientEndAlpha = resetValue;
    }
  }

  get strokeGradientEndColor() {
    return this._strokeGradientEndColor;
  }

  set strokeGradientEndColor(input) {
    let minRGB = 0;
    let maxRGB = 255;

    try {
      if (typeof input !== "object" && !input.red && !input.green && !input.blue) {
        throw new Error(
          "Stroke Gradient End color input must be an object with a red, green, and blue value."
        );
      } else if (input.red < minRGB || input.red > maxRGB) {
        throw new Error(`Red input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.green < minRGB || input.green > maxRGB) {
        throw new Error(`Green input must be between ${minRGB} and ${maxRGB}.`);
      } else if (input.blue < minRGB || input.blue > maxRGB) {
        throw new Error(`Blue input must be between ${minRGB} and ${maxRGB}.`);
      } else {
        const { _red, _green, _blue } = input;
        this._strokeGradientEndColor = {
          _red,
          _green,
          _blue,
        };
      }
    } catch (err) {
      console.warn(err);
      this._strokeGradientEndColor = {
        _red: 255,
        _green: 255,
        _blue: 255,
      };
    }
  }

  // GENERAL VALUES

  get size() {
    return this._size;
  }

  set size(input) {
    const config = Configurations.shapes[this._shape];
    const min = config.minSize || 1;
    const max = config.maxSize || 200;
    const { size: resetValue } = config.default || 10;

    try {
      if (typeof input !== "number") {
        this._size = resetValue;
        throw new Error(
          `Size must be a number between ${min} and ${max}. Resetting value to ${resetValue}.`
        );
      } else if (input < min) {
        this._size = min;
        throw new Error(
          `Size must be a number between ${min} and ${max}. Resetting value to ${min}.`
        );
      } else if (input > max) {
        this._size = max;
        throw new Error(
          `Size must be a number between ${min} and ${max}. Resetting value to ${max}.`
        );
      } else {
        this._size = input;
      }
    } catch (err) {
      console.warn(err);
    }
  }

  get width() {
    return this._width;
  }

  set width(input) {
    const config = Configurations.shapes[this._shape];
    const min = config.minWidth || 1;
    const max = config.maxWidth || 50;
    const resetValue = 4;

    try {
      if (typeof input !== "number") {
        this._width = resetValue;
        throw new Error(
          `Width must be a number between ${min} and ${max}. Resetting value to ${resetValue}.`
        );
      } else if (input < min) {
        this._width = min;
        throw new Error(
          `Width must be a number between ${min} and ${max}. Resetting value to ${min}.`
        );
      } else if (input > max) {
        this._width = max;
        throw new Error(
          `Width must be a number between ${min} and ${max}. Resetting value to ${max}.`
        );
      } else {
        this._width = input;
      }
    } catch (err) {
      console.warn(err);
    }
  }

  get curve() {
    return this._curve;
  }

  set curve(input) {
    const config = Configurations.shapes[this._shape];
    const min = config.minCurve || 0;
    const max = config.maxCurve || 90;
    const resetValue = (min + max) / 2;

    try {
      if (typeof input !== "number") {
        this._curve = resetValue;
        throw new Error(
          `Curve must be a number between ${min} and ${max}. Resetting value to ${resetValue}.`
        );
      } else if (input < min) {
        this._curve = min;
        throw new Error(
          `Curve must be a number between ${min} and ${max}. Resetting value to ${resetValue}.`
        );
      } else if (input > max) {
        this._curve = max;
        throw new Error(
          `Curve must be a number between ${min} and ${max}. Resetting value to ${resetValue}.`
        );
      } else {
        this._curve = input;
      }
    } catch (err) {
      console.warn(err);
    }
  }

  get spacing() {
    return this._spacing;
  }

  set spacing(input) {
    const config = Configurations.shapes[this._shape];
    const min = config.minSpacing || 0;
    const max = config.maxSpacing || 100;
    const resetValue = 10;

    try {
      if (typeof input !== "number") {
        this._spacing = resetValue;
        throw new Error(
          `Spacing must be a number between ${min} and ${max}. Resetting value to ${resetValue}.`
        );
      } else if (input < min) {
        this._spacing = min;
        throw new Error(
          `Spacing must be a number between ${min} and ${max}. Resetting value to ${min}.`
        );
      } else if (input > max) {
        this._spacing = max;
        throw new Error(
          `Spacing must be a number between ${min} and ${max}. Resetting value to ${max}.`
        );
      } else {
        this._spacing = input;
      }
    } catch (err) {
      console.warn(err);
    }
  }

  get length() {
    return this._length;
  }

  set length(input) {
    const config = Configurations.shapes[this._shape];
    const min = config.minLength || 1;
    const max = config.maxLength || 100;
    const resetValue = 10;

    try {
      if (typeof input !== "number") {
        this._length = resetValue;
        throw new Error(
          `Length must be a number between ${min} and ${max}. Resetting value to ${resetValue}.`
        );
      } else if (input < min) {
        this._length = min;
        throw new Error(
          `Length must be a number between ${min} and ${max}. Resetting value to ${min}.`
        );
      } else if (input > max) {
        this._length = max;
        throw new Error(
          `Length must be a number between ${min} and ${max}. Resetting value to ${max}.`
        );
      } else {
        this._length = input;
      }
    } catch (err) {
      console.warn(err);
    }
  }

  get rotation() {
    return this._rotation;
  }

  set rotation(input) {
    const config = Configurations.shapes[this._shape];
    const min = config.minAngle || 0;
    const max = config.maxAngle || 90;
    const resetValue = 0;

    try {
      if (typeof input !== "number") {
        this._rotation = resetValue;
        throw new Error(
          `Rotation input must be a number between ${min} and ${max}. Resetting value to ${resetValue}.`
        );
      } else if (input < min) {
        this._rotation = min;
        throw new Error(
          `Rotation input must be a number between ${min} and ${max}. Resetting value to ${min}.`
        );
      } else if (input > max) {
        this._rotation = max;
        throw new Error(
          `Rotation input must be a number between ${min} and ${max}. Resetting value to ${max}.`
        );
      } else {
        this._rotation = input;
      }
    } catch (err) {
      console.warn(err);
    }
  }

  get posY() {
    return this._posY;
  }

  set posY(input) {
    const config = Configurations.shapes[this._shape];
    const min = config.minPosY || -100;
    const max = config.maxPosY || 100;
    const resetValue = 0;

    try {
      if (typeof input !== "number") {
        this._posY = resetValue;
        throw new Error(
          `Y Position must be a number between ${min} and ${max}. Resetting value to ${resetValue}.`
        );
      } else if (input < min) {
        this._posY = min;
        throw new Error(
          `Y Position must be a number between ${min} and ${max}. Resetting value to ${min}.`
        );
      } else if (input > max) {
        this._posY = max;
        throw new Error(
          `Y Position must be a number between ${min} and ${max}. Resetting value to ${max}.`
        );
      } else {
        this._posY = input;
      }
    } catch (err) {
      console.warn(err);
    }
  }

  get id() {
    return this._id;
  }

  set id(input) {
    try {
      if (typeof input !== "string") {
        throw new Error(`id must be of type string.`);
      } else {
        this._id = input;
      }
    } catch (err) {
      console.warn(err);
      this._id = this.generateNewIdHash();
    }
  }

  generateNewIdHash() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters after the decimal.
    return "_" + Math.random().toString(36).substr(2, 9);
  }
}
