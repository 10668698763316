import React from "react";

// STYLES
import "./select.scss";

function DefaultSelect(props) {
  const { children, classes, selectOnChange, shape } = props;
  return (
    <select className={`crosshairSelect ${classes}`} onChange={selectOnChange} value={shape}>
      {children}
    </select>
  );
}

export default DefaultSelect;
