import React, { Component } from "react";
import { DebounceInput } from "react-debounce-input";

// ==== COMPONENTS
import Colors from "./colors";
import Select from "./select";
import Slider from "./slider";
import Checkbox from "./checkbox";
import { ColorButton, DeleteButton, LightButton } from "./buttons";

// ==== STYLES
import "./controls.scss";

// ==== DATA
import Configurations from "../../configurations/crosshairShapesConfig.json";
import DefaultColors from "./colors/defaultColors.json";

export default class Controls extends Component {
  constructor(props) {
    super();

    this.state = {
      optionsCount: 5,
      activeControl: props.activeControl,
      showStrokeColor: false,
      showFillColor: false,
      showGradientStart: false,
      showGradientEnd: false,
    };

    this.getCrosshairSettingControls = this.getCrosshairSettingControls.bind(this);
  }

  componentDidUpdate() {
    if (this.props.activeControl !== this.state.activeControl) {
      this.setState({
        activeControl: this.props.activeControl,
        activeColorMenu: null,
      });
    }
  }

  render() {
    const { crosshairArray, addCrosshairToArray } = this.props;
    const { optionsCount } = this.state;

    // make an array of numbered titles for use in the accordion heading
    const titles = crosshairArray.map((el, i) => {
      let count = 1;

      for (let j = 0; j < crosshairArray.length; j++) {
        if (i === j) break;
        if (crosshairArray[j].shape === el.shape) count++;
      }

      return `${el.shape} #${count}`;
    });

    return (
      <aside className="controls">
        <h2 className="crosshair__title">Crosshair Settings</h2>

        <ul className="controls__list">
          {crosshairArray.map((crosshair, i) =>
            this.getCrosshairSettingControls(crosshair, i, titles[i])
          )}
        </ul>

        {crosshairArray.length < optionsCount && (
          <LightButton text="Add Element" onClick={() => addCrosshairToArray()} />
        )}
      </aside>
    );
  }

  updateCrosshairColor(crosshair, color, colorType) {
    const { updateCrosshairObject } = this.props;
    const {
      rgb: { r, g, b },
    } = color;
    const { id } = crosshair;

    updateCrosshairObject(id, [
      {
        name: colorType,
        value: {
          _red: r,
          _green: g,
          _blue: b,
        },
      },
    ]);
  }

  getCrosshairSettingControls(crosshair, i, title) {
    const {
      activeControl,
      addCrosshairToArray,
      crosshairArray,
      removeCrosshairFromArray,
      updateActiveCrosshairMenu,
      updateCrosshairObject,
    } = this.props;
    const { optionsCount } = this.state;
    const { id } = crosshair;

    const shapeOptions = Configurations.shapes;
    const shapeConfig = shapeOptions[crosshair.shape];

    return (
      <li className="controlGroup" key={id}>
        {/* ==== HEADER ==== */}

        <h3
          className={`controlGroup__heading ${activeControl === id ? "" : "closed"}`}
          onClick={() => updateActiveCrosshairMenu(id)}
        >
          <span className="controlGroup__headingText">{title}</span>

          <DeleteButton onClick={() => removeCrosshairFromArray(id)} />
        </h3>

        {/* CONTENT */}

        {activeControl === id && (
          <React.Fragment>
            <div className="controlSection">
              <h4 className="controlSection__heading">General</h4>

              {/* Shape is shared by all elements */}
              {this.getShape(crosshair, updateCrosshairObject)}

              {/* Values are shape specific. Some have all or none of these options */}
              {shapeConfig.hasSize && this.getSize(crosshair, updateCrosshairObject)}
              {shapeConfig.hasWidth && this.getWidth(crosshair, updateCrosshairObject)}
              {shapeConfig.canEditPosY && this.getPosY(crosshair, updateCrosshairObject)}
              {shapeConfig.hasCurve && this.getCurve(crosshair, updateCrosshairObject)}
              {shapeConfig.hasSpacing && this.getSpacing(crosshair, updateCrosshairObject)}
              {shapeConfig.hasLength && this.getLength(crosshair, updateCrosshairObject)}
              {shapeConfig.rotationAllowed && this.getRotation(crosshair, updateCrosshairObject)}

              {shapeConfig.canHaveStroke && this.getStrokeColor(crosshair, updateCrosshairObject)}

              {this.getColorFill(crosshair, updateCrosshairObject)}

              {crosshairArray.length < optionsCount && (
                <LightButton
                  size="small"
                  text="Duplicate"
                  onClick={() => addCrosshairToArray(crosshair)}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </li>
    );
  }

  getShape(crosshair, updateCrosshairObject) {
    // ====== SELECT SHAPE TO DRAW ======

    const shapeOptions = Configurations.shapes;
    const { shape, id } = crosshair;

    return (
      <label className="controlSection__group">
        <p className="controlSection__label">Shape</p>
        <Select
          className="crosshairSelect"
          classes="lowercase"
          shape={shape}
          selectOnChange={(e) =>
            updateCrosshairObject(id, [{ name: "shape", value: e.target.value }])
          }
        >
          {Object.keys(shapeOptions).map((shape, i) => (
            <option key={shape} className="crosshairSelect__option">
              {shape}
            </option>
          ))}
        </Select>
      </label>
    );
  }

  getSize(crosshair, updateCrosshairObject) {
    const shapeOptions = Configurations.shapes;
    const { shape, size, id } = crosshair;
    const shapeConfig = shapeOptions[shape];

    // ====== SIZE OPTIONS ======
    return (
      <label className="controlSection__group">
        <p className="controlSection__label">Size</p>
        <Slider
          min={shapeConfig.minSize}
          max={shapeConfig.maxSize}
          value={size}
          name="size"
          sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
        />
        <DebounceInput
          className="controlSection__valueInput"
          type="number"
          debounceTimeout={500}
          min={shapeConfig.minSize}
          max={shapeConfig.maxSize}
          value={size}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            updateCrosshairObject(id, [{ name: "size", value }]);
          }}
        />
      </label>
    );
  }

  getWidth(crosshair, updateCrosshairObject) {
    const shapeOptions = Configurations.shapes;
    const { shape, width, id } = crosshair;
    const shapeConfig = shapeOptions[shape];

    // ====== SIZE OPTIONS ======
    return (
      <label className="controlSection__group">
        <p className="controlSection__label">Width</p>
        <Slider
          min={shapeConfig.minWidth}
          max={shapeConfig.maxWidth}
          value={width}
          name="width"
          sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
        />
        <DebounceInput
          className="controlSection__valueInput"
          type="number"
          debounceTimeout={500}
          min={shapeConfig.minWidth}
          max={shapeConfig.maxWidth}
          value={width}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            updateCrosshairObject(id, [{ name: "width", value }]);
          }}
        />
      </label>
    );
  }

  getCurve(crosshair, updateCrosshairObject) {
    const { shape, curve, id } = crosshair;
    const shapeOptions = Configurations.shapes;
    const { minCurve, maxCurve } = shapeOptions[shape];

    return (
      <label className="controlSection__group">
        <p className="controlSection__label">Curve</p>
        <Slider
          min={minCurve}
          max={maxCurve}
          value={curve}
          name="curve"
          sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
        />
        <DebounceInput
          className="controlSection__valueInput"
          type="number"
          debounceTimeout={500}
          min={minCurve}
          max={maxCurve}
          value={curve || "0"}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            updateCrosshairObject(id, [{ name: "curve", value }]);
          }}
        />
      </label>
    );
  }

  getSpacing(crosshair, updateCrosshairObject) {
    const shapeOptions = Configurations.shapes;
    const { shape, spacing, id } = crosshair;
    const shapeConfig = shapeOptions[shape];

    return (
      <label className="controlSection__group">
        <p className="controlSection__label">Spacing</p>
        <Slider
          min={shapeConfig.minSpacing}
          max={shapeConfig.maxSpacing}
          value={spacing}
          name="spacing"
          sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
        />
        <DebounceInput
          className="controlSection__valueInput"
          type="number"
          debounceTimeout={500}
          min={shapeConfig.minSpacing}
          max={shapeConfig.maxSpacing}
          value={spacing || "0"}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            updateCrosshairObject(id, [{ name: "spacing", value }]);
          }}
        />
      </label>
    );
  }

  getLength(crosshair, updateCrosshairObject) {
    const shapeOptions = Configurations.shapes;
    const { shape, length, id } = crosshair;
    const shapeConfig = shapeOptions[shape];

    return (
      <label className="controlSection__group">
        <p className="controlSection__label">Length</p>
        <Slider
          min={shapeConfig.minLength}
          max={shapeConfig.maxLength}
          value={length}
          name="length"
          sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
        />
        <DebounceInput
          className="controlSection__valueInput"
          type="number"
          debounceTimeout={500}
          min={shapeConfig.minLength}
          max={shapeConfig.maxLength}
          value={length}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            updateCrosshairObject(id, [{ name: "length", value }]);
          }}
        />
      </label>
    );
  }

  getRotation(crosshair, updateCrosshairObject) {
    const shapeOptions = Configurations.shapes;
    const { shape, rotation, id } = crosshair;
    const shapeConfig = shapeOptions[shape];

    return (
      <label className="controlSection__group">
        <p className="controlSection__label">Rotate</p>
        <Slider
          min={shapeConfig.minAngle}
          max={shapeConfig.maxAngle}
          step="5"
          value={rotation}
          name="rotation"
          sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
        />
        <DebounceInput
          className="controlSection__valueInput"
          type="number"
          debounceTimeout={500}
          min={shapeConfig.minAngle}
          max={shapeConfig.maxAngle}
          value={rotation || "0"}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            updateCrosshairObject(id, [{ name: "rotation", value }]);
          }}
        />
        <small className="controlSection__flair rotation">&deg;</small>
      </label>
    );
  }

  getPosY(crosshair, updateCrosshairObject) {
    const shapeOptions = Configurations.shapes;
    const { shape, posY, id } = crosshair;
    const shapeConfig = shapeOptions[shape];

    return (
      <label className="controlSection__group">
        <p className="controlSection__label">Position</p>
        <Slider
          min={shapeConfig.minPosY}
          max={shapeConfig.maxPosY}
          step="1"
          value={posY || 0}
          name="posY"
          sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
        />
        <DebounceInput
          className="controlSection__valueInput"
          type="number"
          min={shapeConfig.minPosY}
          max={shapeConfig.maxPosY}
          value={posY || "0"}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            updateCrosshairObject(id, [{ name: "posY", value }]);
          }}
        />
      </label>
    );
  }

  getColorFill(crosshair, updateCrosshairObject) {
    const shapeOptions = Configurations.shapes;
    const { activeColorMenu } = this.state;
    const { fillColorArray } = DefaultColors;
    const {
      shape,
      fillAlpha,
      fillColor,
      id,
      useGradient,
      gradientStartAlpha,
      gradientStartColor,
      gradientEndAlpha,
      gradientEndColor,
    } = crosshair;
    const shapeConfig = shapeOptions[shape];

    return (
      <React.Fragment>
        <hr className="sectionBreak" />
        <h4 className="controlSection__heading">Fill Color</h4>

        {/* Gradient Start Display & Edit button */}

        {shapeConfig.canHaveFill && useGradient && (
          <label className="controlSection__flex">
            <p className="controlSection__label">Gradient Start</p>
            <ColorButton
              colors={{
                r: gradientStartColor._red,
                g: gradientStartColor._green,
                b: gradientStartColor._blue,
                a: gradientStartAlpha,
              }}
              text="Color"
              buttonActive={activeColorMenu === "fillGradientStart"}
              onClick={() =>
                this.setState({
                  activeColorMenu:
                    activeColorMenu === "fillGradientStart" ? undefined : "fillGradientStart",
                })
              }
            />
          </label>
        )}

        {/* Gradient Start Color Editing Menu */}

        {activeColorMenu === "fillGradientStart" && useGradient && (
          <aside className="controlSection__aside showArrow">
            <label className="controlSection__group">
              <p className="controlSection__label">Opacity</p>
              <Slider
                min="0"
                max="100"
                value={gradientStartAlpha}
                name="gradientStartAlpha"
                classes="alpha"
                sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
              />
              <DebounceInput
                className="controlSection__valueInput"
                type="number"
                min="0"
                max="100"
                debounceTimeout={500}
                value={gradientStartAlpha || "0"}
                onChange={(e) =>
                  updateCrosshairObject(id, [
                    { name: "gradientStartAlpha", value: parseInt(e.target.value) },
                  ])
                }
              />
            </label>

            <Colors
              colorObjectName="gradientStartColor"
              colors={{
                r: gradientStartColor._red,
                g: gradientStartColor._green,
                b: gradientStartColor._blue,
              }}
              crosshair={crosshair}
              defaultColors={fillColorArray}
              updateColor={(crosshair, color) =>
                this.updateCrosshairColor(crosshair, color, "gradientStartColor")
              }
              updateCrosshairObject={updateCrosshairObject}
            />
          </aside>
        )}

        {/* Gradient End Color */}

        {shapeConfig.canHaveFill && useGradient && (
          <label className="controlSection__flex">
            <p className="controlSection__label">Gradient End</p>
            <ColorButton
              colors={{
                r: gradientEndColor._red,
                g: gradientEndColor._green,
                b: gradientEndColor._blue,
                a: gradientEndAlpha,
              }}
              text="Color"
              buttonActive={activeColorMenu === "fillGradientEnd"}
              onClick={() =>
                this.setState({
                  activeColorMenu:
                    activeColorMenu === "fillGradientEnd" ? undefined : "fillGradientEnd",
                })
              }
            />
          </label>
        )}

        {/* Gradient End Color Editing Menu */}

        {activeColorMenu === "fillGradientEnd" && useGradient && (
          <aside className="controlSection__aside showArrow">
            <label className="controlSection__group">
              <p className="controlSection__label">Opacity</p>
              <Slider
                min="0"
                max="100"
                value={gradientEndAlpha}
                name="gradientEndAlpha"
                classes="alpha"
                sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
              />
              <DebounceInput
                className="controlSection__valueInput"
                type="number"
                min="0"
                max="100"
                debounceTimeout={500}
                value={gradientEndAlpha || "0"}
                onChange={(e) =>
                  updateCrosshairObject(id, [
                    { name: "gradientEndAlpha", value: parseInt(e.target.value) },
                  ])
                }
              />
            </label>

            <Colors
              colorObjectName="gradientEndColor"
              colors={{
                r: gradientEndColor._red,
                g: gradientEndColor._green,
                b: gradientEndColor._blue,
              }}
              crosshair={crosshair}
              defaultColors={fillColorArray}
              updateColor={(crosshair, color) =>
                this.updateCrosshairColor(crosshair, color, "gradientEndColor")
              }
              updateCrosshairObject={updateCrosshairObject}
            />
          </aside>
        )}

        {/* Regular Fill Color Edit Button */}

        {shapeConfig.canHaveFill && !useGradient && (
          <label className="controlSection__flex">
            <p className="controlSection__label">Color</p>
            <ColorButton
              colors={{
                r: fillColor._red,
                g: fillColor._green,
                b: fillColor._blue,
                a: fillAlpha,
              }}
              text="Color"
              buttonActive={activeColorMenu === "fillColor"}
              onClick={() =>
                this.setState({
                  activeColorMenu: activeColorMenu === "fillColor" ? undefined : "fillColor",
                })
              }
            />
          </label>
        )}

        {/* Regular Fill Color Menu */}

        {activeColorMenu === "fillColor" && !useGradient && (
          <aside className="controlSection__aside showArrow">
            <label className="controlSection__group">
              <p className="controlSection__label">Opacity</p>
              <Slider
                min="0"
                max="100"
                value={fillAlpha}
                name="fillAlpha"
                classes="alpha"
                sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
              />
              <DebounceInput
                className="controlSection__valueInput"
                type="number"
                min="0"
                max="100"
                debounceTimeout={500}
                value={fillAlpha || "0"}
                onChange={(e) =>
                  updateCrosshairObject(id, [
                    { name: "fillAlpha", value: parseInt(e.target.value) },
                  ])
                }
              />
            </label>

            <Colors
              colorObjectName="fillColor"
              colors={{
                r: fillColor._red,
                g: fillColor._green,
                b: fillColor._blue,
              }}
              crosshair={crosshair}
              defaultColors={fillColorArray}
              updateColor={(crosshair, color) =>
                this.updateCrosshairColor(crosshair, color, "fillColor")
              }
              updateCrosshairObject={updateCrosshairObject}
            />
          </aside>
        )}

        {shapeConfig.canHaveGradient && (
          <label className="controlSection__flex">
            <p className="controlSection__label">Color Using Gradient</p>
            <Checkbox
              type="checkbox"
              className="controlSection__checkInput"
              name="useGradient"
              value={useGradient}
              onChange={(dataArray) => updateCrosshairObject(id, dataArray)}
            />
          </label>
        )}
      </React.Fragment>
    );
  }

  getStrokeColor(crosshair, updateCrosshairObject) {
    const shapeOptions = Configurations.shapes;
    const { activeColorMenu } = this.state;
    const { strokeColorArray } = DefaultColors;
    const {
      id,
      shape,
      strokeAlpha,
      strokeColor,
      strokeWidth,
      useStrokeGradient,
      strokeGradientStartAlpha,
      strokeGradientStartColor,
      strokeGradientEndAlpha,
      strokeGradientEndColor,
    } = crosshair;
    const shapeConfig = shapeOptions[shape];

    return (
      <React.Fragment>
        <hr className="sectionBreak" />
        <h4 className="controlSection__heading">Stroke</h4>

        {shapeConfig.canHaveStroke && (
          <label className="controlSection__group">
            <p className="controlSection__label">Width</p>
            <Slider
              min={shapeConfig.minStrokeWidth || 0}
              max={shapeConfig.maxStrokeWidth || 5}
              value={strokeWidth}
              name="strokeWidth"
              sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
            />
            <DebounceInput
              className="controlSection__valueInput"
              type="number"
              debounceTimeout={500}
              min={shapeConfig.minStrokeWidth || 0}
              max={shapeConfig.maxStrokeWidth || 5}
              value={strokeWidth || "0"}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                updateCrosshairObject(id, [{ name: "strokeWidth", value }]);
              }}
            />
          </label>
        )}

        {/* Gradient Start Display & Edit button */}

        {shapeConfig.canHaveStroke && useStrokeGradient && (
          <label className="controlSection__flex">
            <p className="controlSection__label">Gradient Start</p>
            <ColorButton
              colors={{
                r: strokeGradientStartColor._red,
                g: strokeGradientStartColor._green,
                b: strokeGradientStartColor._blue,
                a: strokeGradientStartAlpha,
              }}
              text="Color"
              buttonActive={activeColorMenu === "strokeGradientStart"}
              onClick={() =>
                this.setState({
                  activeColorMenu:
                    activeColorMenu === "strokeGradientStart" ? undefined : "strokeGradientStart",
                })
              }
            />
          </label>
        )}

        {/* Gradient Start Color Editing Menu */}

        {activeColorMenu === "strokeGradientStart" && useStrokeGradient && (
          <aside className="controlSection__aside showArrow">
            <label className="controlSection__group">
              <p className="controlSection__label">Opacity</p>
              <Slider
                min="0"
                max="100"
                value={strokeGradientStartAlpha}
                name="strokeGradientStartAlpha"
                classes="alpha"
                sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
              />
              <DebounceInput
                className="controlSection__valueInput"
                type="number"
                min="0"
                max="100"
                debounceTimeout={500}
                value={strokeGradientStartAlpha || "0"}
                onChange={(e) =>
                  updateCrosshairObject(id, [
                    { name: "strokeGradientStartAlpha", value: parseInt(e.target.value) },
                  ])
                }
              />
            </label>

            <Colors
              colorObjectName="strokeGradientStartColor"
              colors={{
                r: strokeGradientStartColor._red,
                g: strokeGradientStartColor._green,
                b: strokeGradientStartColor._blue,
              }}
              crosshair={crosshair}
              defaultColors={strokeColorArray}
              updateColor={(crosshair, color) =>
                this.updateCrosshairColor(crosshair, color, "strokeGradientStartColor")
              }
              updateCrosshairObject={updateCrosshairObject}
            />
          </aside>
        )}

        {/* Gradient End Color */}

        {shapeConfig.canHaveStroke && useStrokeGradient && (
          <label className="controlSection__flex">
            <p className="controlSection__label">Gradient End</p>
            <ColorButton
              colors={{
                r: strokeGradientEndColor._red,
                g: strokeGradientEndColor._green,
                b: strokeGradientEndColor._blue,
                a: strokeGradientEndAlpha,
              }}
              text="Color"
              buttonActive={activeColorMenu === "strokeGradientEnd"}
              onClick={() =>
                this.setState({
                  activeColorMenu:
                    activeColorMenu === "strokeGradientEnd" ? undefined : "strokeGradientEnd",
                })
              }
            />
          </label>
        )}

        {/* Gradient End Color Editing Menu */}

        {activeColorMenu === "strokeGradientEnd" && useStrokeGradient && (
          <aside className="controlSection__aside showArrow">
            <label className="controlSection__group">
              <p className="controlSection__label">Opacity</p>
              <Slider
                min="0"
                max="100"
                value={strokeGradientEndAlpha}
                name="strokeGradientEndAlpha"
                classes="alpha"
                sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
              />
              <DebounceInput
                className="controlSection__valueInput"
                type="number"
                min="0"
                max="100"
                debounceTimeout={500}
                value={strokeGradientEndAlpha || "0"}
                onChange={(e) =>
                  updateCrosshairObject(id, [
                    { name: "strokeGradientEndAlpha", value: parseInt(e.target.value) },
                  ])
                }
              />
            </label>

            <Colors
              colorObjectName="strokeGradientEndColor"
              colors={{
                r: strokeGradientEndColor._red,
                g: strokeGradientEndColor._green,
                b: strokeGradientEndColor._blue,
              }}
              crosshair={crosshair}
              defaultColors={strokeColorArray}
              updateColor={(crosshair, color) =>
                this.updateCrosshairColor(crosshair, color, "strokeGradientEndColor")
              }
              updateCrosshairObject={updateCrosshairObject}
            />
          </aside>
        )}

        {/* Regular Fill Color Edit Button */}
        {shapeConfig.canHaveStroke && !useStrokeGradient && (
          <label className="controlSection__flex">
            <p className="controlSection__label">Stroke Color</p>
            <ColorButton
              colors={{
                r: strokeColor._red,
                g: strokeColor._green,
                b: strokeColor._blue,
                a: strokeAlpha,
              }}
              text="Stroke"
              buttonActive={activeColorMenu === "strokeColor"}
              onClick={() =>
                this.setState({
                  activeColorMenu: activeColorMenu === "strokeColor" ? undefined : "strokeColor",
                })
              }
            />
          </label>
        )}

        {/* Regular Fill Color Menu */}

        {activeColorMenu === "strokeColor" && !useStrokeGradient && (
          <aside className="controlSection__aside showArrow">
            <label className="controlSection__group">
              <p className="controlSection__label">Opacity</p>
              <Slider
                min="0"
                max="100"
                value={crosshair._strokeAlpha}
                name="strokeAlpha"
                classes="alpha"
                sliderUpdate={(dataArray) => updateCrosshairObject(id, dataArray)}
              />

              <DebounceInput
                className="controlSection__valueInput"
                type="number"
                debounceTimeout={500}
                min="0"
                max="100"
                value={strokeAlpha || "0"}
                onChange={(e) =>
                  updateCrosshairObject(id, [{ name: "strokeAlpha", value: e.target.value }])
                }
              />
            </label>
            <Colors
              colorObjectName="strokeColor"
              colors={{ r: strokeColor._red, g: strokeColor._green, b: strokeColor._blue }}
              crosshair={crosshair}
              defaultColors={strokeColorArray}
              updateColor={(crosshair, color) =>
                this.updateCrosshairColor(crosshair, color, "strokeColor")
              }
              updateCrosshairObject={updateCrosshairObject}
            />
          </aside>
        )}

        {shapeConfig.canHaveStrokeGradient && (
          <label className="controlSection__flex">
            <p className="controlSection__label">Color Using Gradient</p>
            <Checkbox
              type="checkbox"
              className="controlSection__checkInput"
              name="useStrokeGradient"
              value={useStrokeGradient}
              onChange={(dataArray) => updateCrosshairObject(id, dataArray)}
            />
          </label>
        )}
      </React.Fragment>
    );
  }
}
