import React, { Component } from "react";

// Functional Components
import Crosshair from "../../components/functional/crosshair";
import { parseShareURL } from "../../components/functional/shareURL";

// Dom Elements
import Header from "../../components/header";
import Footer from "../../components/footer";
import CrosshairCanvas from "../../components/canvas";
import Instructions from "../../components/instructions";
import { LinkButton } from "../../components/controls/buttons";
import Controls from "../../components/controls";
import Examples from "../../components/examples";

// Configurations
import Configurations from "../../configurations/crosshairShapesConfig.json";
import Suggestions from "../../configurations/crosshairSuggestions.json";

import "./homepage.scss";

export default class Homepage extends Component {
  constructor() {
    super();

    let crosshairArray = [];
    let errorMessage = null;

    if (window.parent.location !== window.location) {
      try {
        let parsed = parseShareURL(window.parent.referrer);
        crosshairArray = parsed;
      } catch (err) {
        console.error(err);
        errorMessage =
          "Sorry, there was a problem generating a crosshair from the URL you provided.";
      }
    } else if (window.location.search) {
      try {
        let parsed = parseShareURL(window.location.href);
        crosshairArray = parsed;
      } catch (err) {
        console.error(err);
        errorMessage =
          "Sorry, there was a problem generating a crosshair from the URL you provided.";
      }
    } else {
      crosshairArray.push(new Crosshair());
    }

    this.state = {
      activeControl: crosshairArray[0].id,
      crosshairArray: crosshairArray,
      errorMessage,
      showInstallInstructions: false,
    };

    this.addCrosshairToArray = this.addCrosshairToArray.bind(this);
    this.removeCrosshairFromArray = this.removeCrosshairFromArray.bind(this);
    this.setCrosshairFromSuggestion = this.setCrosshairFromSuggestion.bind(this);
    this.updateActiveCrosshairMenu = this.updateActiveCrosshairMenu.bind(this);
    this.updateCrosshairObject = this.updateCrosshairObject.bind(this);
  }

  render() {
    const { activeControl, crosshairArray, errorMessage, showInstallInstructions } = this.state;

    return (
      <div className="homepage">
        <main className="crosshair">
          <Header />
          <div className="crosshair__heading">
            <LinkButton
              text={
                showInstallInstructions
                  ? "Back to Crosshair Generator"
                  : "How do I install a crosshair in KovaaK's?"
              }
              onClick={() => this.setState({ showInstallInstructions: !showInstallInstructions })}
            />
          </div>

          {errorMessage && <p className="crosshair__error">{errorMessage}</p>}

          {!showInstallInstructions && (
            <React.Fragment>
              <CrosshairCanvas id="crosshair__display" crosshairArray={crosshairArray} />

              <section className="crosshair__section thin">
                <Controls
                  crosshairArray={crosshairArray}
                  activeControl={activeControl}
                  updateActiveCrosshairMenu={(id) => this.updateActiveCrosshairMenu(id)}
                  updateCrosshairObject={(id, dataArray) =>
                    this.updateCrosshairObject(id, dataArray)
                  }
                  addCrosshairToArray={(data) => this.addCrosshairToArray(data)}
                  removeCrosshairFromArray={(id) => this.removeCrosshairFromArray(id)}
                />
              </section>

              <section className="crosshair__section wide">
                <Examples setCrosshair={this.setCrosshairFromSuggestion} />
              </section>
            </React.Fragment>
          )}

          {showInstallInstructions && <Instructions />}
        </main>
        <Footer />
      </div>
    );
  }

  addCrosshairToArray(crosshairParams) {
    let { crosshairArray } = this.state;
    const newCrosshair = new Crosshair(crosshairParams);
    const activeControl = newCrosshair.id;

    crosshairArray.push(newCrosshair);
    this.setState({ crosshairArray, activeControl });
  }

  removeCrosshairFromArray(id) {
    const { crosshairArray } = this.state;

    const newArray = crosshairArray.filter((crosshair) => crosshair.id !== id);
    const lastElement = newArray[newArray.length - 1];
    const newActiveId = lastElement ? lastElement.id : null;

    this.setState({ crosshairArray: newArray, activeControl: newActiveId });
  }

  setCrosshairFromSuggestion(name) {
    const { suggestions } = Suggestions;
    const suggestionData = suggestions.find((suggestion) => suggestion.name === name);
    const { buildURL } = suggestionData;

    const crosshairArray = parseShareURL(window.location.origin + "/?" + buildURL);

    this.setState({ crosshairArray: crosshairArray });
  }

  updateActiveCrosshairMenu(id) {
    const { activeControl } = this.state;
    this.setState({ activeControl: id === activeControl ? null : id });
  }

  updateCrosshairObject(id, propsToUpdate) {
    // This how every crosshair change is applied in the App.
    // id is the string id of the crosshair which is being updated.
    // propsToUpdate is an array of objects, {name: ?, value: ?}.

    let { crosshairArray } = this.state;
    const { shapes } = Configurations;
    const isShapeChange = propsToUpdate.some((param) => param.name === "shape");

    // Find the crosshair being updated and determine its index in the array of crosshairs.
    let crosshairToUpdate = crosshairArray.find((obj) => obj.id === id);
    let index = crosshairArray.indexOf(crosshairToUpdate);

    if (isShapeChange) {
      // If the change involves a shape change, reset to that shape's defaults from the configurations.
      // Then we check what values are retained between the two shapes and set those values over the configuration values.

      const newShape = propsToUpdate.find((param) => param.name === "shape").value;
      let newCrosshair = new Crosshair({ shape: newShape });

      for (let [key, value] of Object.entries(newCrosshair)) {
        // For any values of the new crosshair that are not null, find the value (if it exists) from the previous settings.
        // Replace the new, default setting with the prior setting value.

        if (
          key !== "_shape" &&
          value !== undefined &&
          crosshairToUpdate[key.replace("_", "")] !== undefined
        ) {
          newCrosshair[key.replace("_", "")] = crosshairToUpdate[key.replace("_", "")];
        }
      }

      // Special check for gradients. If it was true, but the new shape cannot have them, set to false.
      if (!shapes[newCrosshair.shape].canHaveGradient) newCrosshair.useGradient = false;

      crosshairArray[index] = newCrosshair;
    } else {
      // If the change is just a value update, loop through all changes and apply them to the class before returning it to the state.
      propsToUpdate.forEach((prop) => {
        crosshairToUpdate[prop.name] = prop.value;
      });

      crosshairArray[index] = crosshairToUpdate;
    }

    this.setState({
      crosshairArray: crosshairArray,
    });
  }
}
