import Crosshair from "./crosshair";

function drawCrosshair(ctx, canvas, crosshair = new Crosshair(), mousePos) {
  let {
    fillAlpha,
    fillColor,
    useGradient,
    gradientStartColor,
    gradientStartAlpha,
    gradientEndColor,
    gradientEndAlpha,
    useStrokeGradient,
    strokeGradientStartColor,
    strokeGradientStartAlpha,
    strokeGradientEndColor,
    strokeGradientEndAlpha,
    size,
    width,
    curve,
    spacing,
    length,
    shape,
    posY,
    strokeAlpha,
    strokeColor,
    strokeWidth,
    rotation,
  } = crosshair;

  const center = {
    x: mousePos ? mousePos.x : canvas.width / 2,
    y: mousePos ? mousePos.y : canvas.height / 2,
  };

  if (shape === "crosshair") {
    drawCrosshairLines();
  } else if (shape === "circle") {
    drawCircle();
  } else if (shape === "square") {
    drawSquare();
  } else if (shape === "triangle") {
    drawTriangle();
  } else if (shape === "two lines") {
    drawTwoLines();
  } else if (shape === "three straight lines") {
    drawThreeLines();
  } else if (shape === "three angled lines") {
    drawThreeAngledLines();
  } else if (shape === "t-shape") {
    drawTShape();
  } else if (shape === "one curve") {
    drawSingleCurve();
  } else if (shape === "two curves") {
    drawDualCurves();
  } else if (shape === "three curves") {
    drawThreeCurves();
  } else if (shape === "four curves") {
    drawFourCurves();
  } else if (shape === "arrow") {
    drawSingleArrow();
  } else if (shape === "dual arrows") {
    drawDualArrows();
  } else if (shape === "angled brackets") {
    drawAngledBrackets();
  }

  function drawCircle() {
    let circle = new Path2D();

    ctx.save();

    if (useGradient) {
      var grd = ctx.createRadialGradient(center.x, center.y, 0, center.x, center.y, size);
      grd.addColorStop(
        0,
        `rgba(${gradientStartColor._red}, ${gradientStartColor._green}, ${
          gradientStartColor._blue
        }, ${gradientStartAlpha / 100}`
      );
      grd.addColorStop(
        1,
        `rgba(${gradientEndColor._red}, ${gradientEndColor._green}, ${gradientEndColor._blue}, ${
          gradientEndAlpha / 100
        })`
      );
      ctx.fillStyle = grd;
    } else {
      ctx.fillStyle = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
        fillAlpha / 100
      })`;
    }

    // draw shape
    circle.arc(center.x, center.y, size, 0, 2 * Math.PI);
    ctx.fill(circle);

    if (strokeWidth > 0) {
      const strokeRadius = parseInt(size) + parseInt(strokeWidth) / Math.PI;

      ctx.beginPath();
      ctx.arc(center.x, center.y, strokeRadius, 0, 6.283185307179586, false);
      ctx.strokeStyle = `rgba(${strokeColor._red},${strokeColor._green},${strokeColor._blue},${
        strokeAlpha / 100
      })`;
      ctx.lineWidth = strokeWidth;
      ctx.stroke();
      ctx.closePath();
    }

    ctx.restore();
  }

  function drawSquare() {
    const squareCenter = {
      x: center.x - size / 2,
      y: center.y - size / 2,
    };

    ctx.save();

    if (rotation) {
      const midPoint = {
        x: center.x,
        y: center.y,
      };

      ctx.translate(midPoint.x, midPoint.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-midPoint.x, -midPoint.y);
    }

    ctx.fillStyle = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
      fillAlpha / 100
    })`;
    ctx.fillRect(squareCenter.x, squareCenter.y, size, size);

    if (strokeWidth > 0) {
      ctx.beginPath();

      ctx.rect(squareCenter.x, squareCenter.y, size, size);

      ctx.strokeStyle = `rgba(${strokeColor._red},${strokeColor._green},${strokeColor._blue},${
        strokeAlpha / 100
      })`;
      ctx.lineWidth = strokeWidth;
      ctx.stroke();

      ctx.closePath();
    }

    ctx.restore();
  }

  function drawTriangle() {
    // in the triangle, the length paramter is used as the length of each side of the triangle.

    if (strokeWidth) {
      drawTriangleLines(width + strokeWidth, strokeColor);
    }

    drawTriangleLines(width, fillColor);

    function drawTriangleLines(width, color) {
      const verticalShift = (length * Math.sqrt(3)) / 3;
      const { _red, _green, _blue } = color;

      ctx.save();
      ctx.beginPath();

      if (rotation) {
        ctx.translate(center.x, center.y);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.translate(-center.x, -center.y);
      }

      // line starts from center bottom to prevent weird line width clipping issues
      // ctx.moveTo(center.x, center.y + length * Math.sqrt(2));
      ctx.moveTo(center.x, center.y + verticalShift);
      ctx.lineTo(center.x - length, center.y + verticalShift);
      ctx.lineTo(center.x, center.y - (Math.sqrt(3) / 2) * (length * 2) + verticalShift);
      ctx.lineTo(center.x + length, center.y + verticalShift);
      ctx.lineTo(center.x, center.y + verticalShift);

      ctx.strokeStyle = `rgba(${_red}, ${_green}, ${_blue}, ${fillAlpha / 100})`;
      ctx.lineWidth = width;

      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }
  }

  function drawCrosshairLines() {
    // make sure all numerical values are in number format
    spacing = parseInt(spacing);
    length = parseInt(length);

    if (strokeWidth) {
      // Up Line stroke
      drawLineStroke(
        { x: center.x, y: center.y - spacing + strokeWidth },
        { x: center.x, y: center.y - spacing - length - strokeWidth }
      );

      // Line Down
      drawLineStroke(
        { x: center.x, y: center.y + spacing - strokeWidth },
        { x: center.x, y: center.y + spacing + length + strokeWidth }
      );

      // Right Line
      drawLineStroke(
        { x: center.x + spacing - strokeWidth, y: center.y },
        { x: center.x + spacing + length + strokeWidth, y: center.y }
      );

      // Left Line
      drawLineStroke(
        { x: center.x - spacing + strokeWidth, y: center.y },
        { x: center.x - spacing - length - strokeWidth, y: center.y }
      );
    }

    // Up Line
    drawLine(
      { x: center.x, y: center.y - spacing },
      { x: center.x, y: center.y - spacing - length }
    );

    // Line Down
    drawLine(
      { x: center.x, y: center.y + spacing },
      { x: center.x, y: center.y + spacing + length }
    );

    // Right Line
    drawLine(
      { x: center.x + spacing, y: center.y },
      { x: center.x + spacing + length, y: center.y }
    );

    // Left Line
    drawLine(
      { x: center.x - spacing, y: center.y },
      { x: center.x - spacing - length, y: center.y }
    );
  }

  function drawTwoLines() {
    // make sure all numerical values are in number format
    spacing = parseInt(spacing);
    length = parseInt(length);
    posY = parseInt(posY);

    if (strokeWidth) {
      // Right Line
      drawLineStroke(
        { x: center.x + spacing - strokeWidth, y: center.y + posY },
        { x: center.x + spacing + length + strokeWidth, y: center.y + posY }
      );

      // Left Line
      drawLineStroke(
        { x: center.x - spacing + strokeWidth, y: center.y + posY },
        { x: center.x - spacing - length - strokeWidth, y: center.y + posY }
      );
    }

    // Right Line
    drawLine(
      { x: center.x + spacing, y: center.y + posY },
      { x: center.x + spacing + length, y: center.y + posY }
    );

    // Left Line
    drawLine(
      { x: center.x - spacing, y: center.y + posY },
      { x: center.x - spacing - length, y: center.y + posY }
    );
  }

  function drawThreeLines() {
    // make sure all numerical values are in number format
    spacing = parseInt(spacing);
    length = parseInt(length);

    if (strokeWidth) {
      // Line Down
      drawLineStroke(
        { x: center.x, y: center.y + spacing - strokeWidth },
        { x: center.x, y: center.y + spacing + length + strokeWidth }
      );

      // Right Line
      drawLineStroke(
        { x: center.x + spacing - strokeWidth, y: center.y },
        { x: center.x + spacing + length + strokeWidth, y: center.y }
      );

      // Left Line
      drawLineStroke(
        { x: center.x - spacing + strokeWidth, y: center.y },
        { x: center.x - spacing - length - strokeWidth, y: center.y }
      );
    }

    // Line Down
    drawLine(
      { x: center.x, y: center.y + spacing },
      { x: center.x, y: center.y + spacing + length }
    );

    // Right Line
    drawLine(
      { x: center.x + spacing, y: center.y },
      { x: center.x + spacing + length, y: center.y }
    );

    // Left Line
    drawLine(
      { x: center.x - spacing, y: center.y },
      { x: center.x - spacing - length, y: center.y }
    );
  }

  function drawThreeAngledLines() {
    if (strokeWidth) {
      // Up Line stroke
      drawLineStroke(
        { x: center.x, y: center.y - spacing + strokeWidth },
        { x: center.x, y: center.y - spacing - length - strokeWidth }
      );
      // Bottom Left Diagonal
      drawLineStroke(
        { x: center.x - spacing + strokeWidth, y: center.y },
        { x: center.x - spacing - length - strokeWidth, y: center.y },
        -30
      );

      // Bottom Right Diagonal
      drawLineStroke(
        { x: center.x + spacing - strokeWidth, y: center.y },
        { x: center.x + spacing + length + strokeWidth, y: center.y },
        30
      );
    }

    // Up Line
    drawLine(
      { x: center.x, y: center.y - spacing },
      { x: center.x, y: center.y - spacing - length }
    );

    // Bottom Left Diagonal
    drawLine(
      { x: center.x - spacing, y: center.y },
      { x: center.x - spacing - length, y: center.y },
      -30
    );

    // Bottom Right Diagonal
    drawLine(
      { x: center.x + spacing, y: center.y },
      { x: center.x + spacing + length, y: center.y },
      30
    );
  }

  function drawTShape() {
    if (strokeWidth) {
      // Line Down
      drawLineStroke(
        { x: center.x, y: center.y - strokeWidth },
        { x: center.x, y: center.y + size + strokeWidth }
      );

      // Right Line
      drawLineStroke(
        { x: center.x - strokeWidth, y: center.y },
        { x: center.x + length + strokeWidth, y: center.y }
      );

      // Left Line
      drawLineStroke(
        { x: center.x + strokeWidth, y: center.y },
        { x: center.x - length - strokeWidth, y: center.y }
      );
    }

    // Line Down
    drawLine({ x: center.x, y: center.y }, { x: center.x, y: center.y + size });

    // Right Line
    drawLine({ x: center.x - 1, y: center.y }, { x: center.x + length, y: center.y });

    // Left Line
    drawLine({ x: center.x, y: center.y }, { x: center.x - length, y: center.y });
  }

  function drawSingleCurve() {
    spacing = parseInt(spacing);
    curve = parseInt(curve);

    ctx.save();

    if (rotation) {
      ctx.translate(center.x, center.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-center.x, -center.y);
    }

    if (strokeWidth) {
      ctx.beginPath();
      ctx.arc(center.x, center.y, spacing, 0, (Math.PI * curve) / 100, false);

      ctx.strokeStyle = `rgba(${strokeColor._red}, ${strokeColor._green}, ${strokeColor._blue}, ${
        strokeAlpha / 100
      })`;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();
    }

    // ===== left stroke
    ctx.beginPath();
    ctx.arc(center.x, center.y, spacing, 0, (Math.PI * curve) / 100, false);

    ctx.strokeStyle = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
      fillAlpha / 100
    })`;
    ctx.lineWidth = width;
    ctx.stroke();
    ctx.closePath();

    ctx.restore();
  }

  function drawDualCurves() {
    spacing = parseInt(spacing);
    curve = parseInt(curve);

    const colorStr = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
      fillAlpha / 100
    })`;

    ctx.save();

    if (rotation) {
      ctx.translate(center.x, center.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-center.x, -center.y);
    }

    if (strokeWidth) {
      ctx.beginPath();
      ctx.arc(center.x, center.y, spacing, Math.PI, (curve / 100 - 1) * Math.PI, false);

      ctx.strokeStyle = `rgba(${strokeColor._red}, ${strokeColor._green}, ${strokeColor._blue}, ${
        strokeAlpha / 100
      })`;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();

      ctx.beginPath();
      ctx.arc(center.x, center.y, spacing, 0, (curve / 100) * Math.PI, false);

      ctx.strokeStyle = `rgba(${strokeColor._red}, ${strokeColor._green}, ${strokeColor._blue}, ${
        strokeAlpha / 100
      })`;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();
    }

    // ===== left stroke
    ctx.beginPath();
    ctx.arc(center.x, center.y, spacing, Math.PI, (curve / 100 - 1) * Math.PI, false);

    ctx.strokeStyle = colorStr;
    ctx.lineWidth = width;
    ctx.stroke();
    ctx.closePath();

    // ===== right stroke
    ctx.beginPath();
    ctx.arc(center.x, center.y, spacing, 0, (curve / 100) * Math.PI, false);

    ctx.strokeStyle = colorStr;
    ctx.lineWidth = width;
    ctx.stroke();
    ctx.closePath();

    ctx.restore();
  }

  function drawThreeCurves() {
    spacing = parseInt(spacing);
    curve = parseInt(curve);

    ctx.save();

    if (rotation) {
      ctx.translate(center.x, center.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-center.x, -center.y);
    }

    if (strokeWidth) {
      const colorStyle = `rgba(${strokeColor._red}, ${strokeColor._green}, ${strokeColor._blue}, ${
        strokeAlpha / 100
      })`;

      ctx.beginPath();
      ctx.arc(center.x, center.y, spacing, 0, (curve / 100) * Math.PI, false);
      ctx.strokeStyle = colorStyle;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();

      ctx.beginPath();
      ctx.arc(
        center.x,
        center.y,
        spacing,
        (2 / 3) * Math.PI,
        (2 / 3) * Math.PI + (curve / 100) * Math.PI,
        false
      );
      ctx.strokeStyle = colorStyle;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();

      ctx.beginPath();
      ctx.arc(
        center.x,
        center.y,
        spacing,
        (1 + 1 / 3) * Math.PI,
        (1 + 1 / 3) * Math.PI + (curve / 100) * Math.PI,
        false
      );
      ctx.strokeStyle = colorStyle;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();
    }

    // ===== bottom right stroke
    ctx.beginPath();
    ctx.arc(center.x, center.y, spacing, 0, (curve / 100) * Math.PI, false);
    stroke();

    // ===== bottom left stroke
    ctx.beginPath();
    ctx.arc(
      center.x,
      center.y,
      spacing,
      (2 / 3) * Math.PI,
      (2 / 3) * Math.PI + (curve / 100) * Math.PI,
      false
    );
    stroke();

    // ===== top stroke
    ctx.beginPath();
    ctx.arc(
      center.x,
      center.y,
      spacing,
      (1 + 1 / 3) * Math.PI,
      (1 + 1 / 3) * Math.PI + (curve / 100) * Math.PI,
      false
    );
    stroke();

    ctx.restore();

    function stroke() {
      const colorStr = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
        fillAlpha / 100
      })`;

      ctx.strokeStyle = colorStr;
      ctx.lineWidth = width;
      ctx.stroke();
      ctx.closePath();
    }
  }

  function drawFourCurves() {
    spacing = parseInt(spacing);
    curve = parseInt(curve);

    ctx.save();

    if (rotation) {
      ctx.translate(center.x, center.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-center.x, -center.y);
    }

    if (strokeWidth) {
      const colorStyle = `rgba(${strokeColor._red}, ${strokeColor._green}, ${strokeColor._blue}, ${
        strokeAlpha / 100
      })`;

      ctx.beginPath();
      ctx.arc(center.x, center.y, spacing, 1.5 * Math.PI, Math.PI * (1.5 + curve / 100), false);
      ctx.strokeStyle = colorStyle;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();

      ctx.beginPath();
      ctx.arc(center.x, center.y, spacing, 0, (curve / 100) * Math.PI, false);
      ctx.strokeStyle = colorStyle;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();

      ctx.beginPath();
      ctx.arc(center.x, center.y, spacing, 0.5 * Math.PI, Math.PI * (0.5 + curve / 100), false);
      ctx.strokeStyle = colorStyle;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();

      ctx.beginPath();
      ctx.arc(center.x, center.y, spacing, Math.PI, (curve / 100 - 1) * Math.PI, false);
      ctx.strokeStyle = colorStyle;
      ctx.lineWidth = width + strokeWidth;
      ctx.stroke();
      ctx.closePath();
    }

    // ===== top stroke
    ctx.beginPath();
    ctx.arc(center.x, center.y, spacing, 1.5 * Math.PI, Math.PI * (1.5 + curve / 100), false);
    stroke();

    // ===== right stroke
    ctx.beginPath();
    ctx.arc(center.x, center.y, spacing, 0, (curve / 100) * Math.PI, false);
    stroke();

    // ===== bottom stroke
    ctx.beginPath();
    ctx.arc(center.x, center.y, spacing, 0.5 * Math.PI, Math.PI * (0.5 + curve / 100), false);
    stroke();

    // ===== left stroke
    ctx.beginPath();
    ctx.arc(center.x, center.y, spacing, Math.PI, (curve / 100 - 1) * Math.PI, false);
    stroke();

    ctx.restore();

    function stroke() {
      const colorStr = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
        fillAlpha / 100
      })`;

      ctx.strokeStyle = colorStr;
      ctx.lineWidth = width;
      ctx.stroke();
      ctx.closePath();
    }
  }

  // TODO: OLD DUAL CURVES. MAYBE RE-USE AS SOMETHING ELSE?
  // function drawDualCurves() {
  //   const midPoint = {
  //     x: center.x,
  //     y: center.y,
  //   };

  //   const colorStr = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
  //     fillAlpha / 100
  //   })`;

  //   ctx.save();

  //   if (rotation) {
  //     ctx.translate(midPoint.x, midPoint.y);
  //     ctx.rotate((rotation * Math.PI) / 180);
  //     ctx.translate(-midPoint.x, -midPoint.y);
  //   }

  //   // left stroke
  //   ctx.beginPath();
  //   ctx.moveTo(center.x - parseInt(spacing), center.y - parseInt(length));

  //   ctx.strokeStyle = colorStr;
  //   ctx.lineWidth = width;

  //   ctx.bezierCurveTo(
  //     center.x - parseInt(spacing) - parseInt(length), // + parseInt(curve)),
  //     center.y - parseInt(curve) - parseInt(length) / 2,

  //     center.x - parseInt(spacing) - parseInt(length), // + parseInt(curve)),
  //     center.y + parseInt(curve) + parseInt(length) / 2,

  //     center.x - parseInt(spacing),
  //     center.y + parseInt(length)
  //   );
  //   ctx.stroke();

  //   // right stroke
  //   ctx.beginPath();
  //   ctx.moveTo(center.x + parseInt(spacing), center.y + parseInt(length));

  //   ctx.strokeStyle = colorStr;
  //   ctx.lineWidth = width;

  //   ctx.bezierCurveTo(
  //     center.x + parseInt(spacing) + parseInt(length), //+ parseInt(curve)),
  //     center.y + parseInt(curve) + parseInt(length) / 2,

  //     center.x + parseInt(spacing) + parseInt(length), // + parseInt(curve)),
  //     center.y - parseInt(curve) - parseInt(length) / 2,

  //     center.x + parseInt(spacing),
  //     center.y - parseInt(length)
  //   );
  //   ctx.stroke();

  //   ctx.closePath();
  //   ctx.restore();
  // }

  function drawSingleArrow() {
    // One Arrow, starting position is directly below the midpoint

    const arrowCenter = {
      x: center.x,
      y: center.y + width / 1.33,
    };

    ctx.save();

    if (rotation) {
      ctx.translate(center.x, center.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-center.x, -center.y);
    }

    // Stroke Path
    if (strokeWidth) {
      ctx.beginPath();
      ctx.moveTo(
        center.x - length - strokeWidth / 2,
        arrowCenter.y + posY + length + strokeWidth / 2
      );
      ctx.lineTo(center.x, arrowCenter.y + posY);
      ctx.lineTo(
        center.x + length + strokeWidth / 2,
        arrowCenter.y + posY + length + strokeWidth / 2
      );

      ctx.strokeStyle = `rgba(${strokeColor._red}, ${strokeColor._green}, ${strokeColor._blue}, ${
        strokeAlpha / 100
      })`;
      ctx.lineWidth = width + strokeWidth;

      ctx.stroke();
      ctx.closePath();
    }

    // Arrow Path
    ctx.beginPath();
    ctx.moveTo(center.x - length, arrowCenter.y + posY + length);
    ctx.lineTo(center.x, arrowCenter.y + posY);
    ctx.lineTo(center.x + length, arrowCenter.y + posY + length);

    ctx.strokeStyle = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
      fillAlpha / 100
    })`;
    ctx.lineWidth = width;

    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }

  function drawDualArrows() {
    // Two Arrows, starting position is directly above and below the midpoint

    ctx.save();

    if (rotation) {
      ctx.translate(center.x, center.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-center.x, -center.y);
    }

    if (strokeWidth) {
      ctx.beginPath();
      ctx.moveTo(
        center.x - length - strokeWidth / 2,
        center.y - width / 1.33 - spacing - length - strokeWidth / 2
      );
      ctx.lineTo(center.x, center.y - width / 1.33 - spacing);
      ctx.lineTo(
        center.x + length + strokeWidth / 2,
        center.y - width / 1.33 - spacing - length - strokeWidth / 2
      );

      ctx.moveTo(
        center.x - length - strokeWidth / 2,
        center.y + width / 1.33 + spacing + length + strokeWidth / 2
      );
      ctx.lineTo(center.x, center.y + width / 1.33 + spacing);
      ctx.lineTo(
        center.x + length + strokeWidth / 2,
        center.y + width / 1.33 + spacing + length + strokeWidth / 2
      );

      ctx.strokeStyle = `rgba(${strokeColor._red}, ${strokeColor._green}, ${strokeColor._blue}, ${
        strokeAlpha / 100
      })`;
      ctx.lineWidth = width + strokeWidth;

      ctx.stroke();
      ctx.closePath();
    }

    ctx.beginPath();
    // top arrow
    ctx.moveTo(center.x - length, center.y - width / 1.33 - spacing - length);
    ctx.lineTo(center.x, center.y - width / 1.33 - spacing);
    ctx.lineTo(center.x + length, center.y - width / 1.33 - spacing - length);

    // bottom arrow
    ctx.moveTo(center.x - length, center.y + width / 1.33 + spacing + length);
    ctx.lineTo(center.x, center.y + width / 1.33 + spacing);
    ctx.lineTo(center.x + length, center.y + width / 1.33 + spacing + length);

    ctx.strokeStyle = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
      fillAlpha / 100
    })`;
    ctx.lineWidth = width;

    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }

  function drawAngledBrackets() {
    ctx.save();

    if (rotation) {
      ctx.translate(center.x, center.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-center.x, -center.y);
    }

    if (strokeWidth) {
      drawBracketStroke();
      drawBracketStroke(90);
      drawBracketStroke(180);
      drawBracketStroke(-90);
    }

    drawBracket();
    drawBracket(90);
    drawBracket(180);
    drawBracket(-90);
    ctx.restore();

    function drawBracket(rotation) {
      ctx.save();
      ctx.beginPath();

      if (rotation) {
        ctx.translate(center.x, center.y);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.translate(-center.x, -center.y);
      }

      const modifier = 2.5;

      // The length value represents the small sides of the invisible triangle.
      // This means the long line that creates the angle of the bracket is Math.sqrt(length^2 + length^2)
      ctx.moveTo(center.x - spacing - length * modifier, center.y - spacing);
      ctx.lineTo(center.x - spacing - length * modifier, center.y - spacing - length);
      ctx.lineTo(center.x - spacing - length, center.y - spacing - length * modifier);
      ctx.lineTo(center.x - spacing, center.y - spacing - length * modifier);

      ctx.strokeStyle = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
        fillAlpha / 100
      })`;
      ctx.lineWidth = width;

      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }

    function drawBracketStroke(rotation) {
      ctx.save();
      ctx.beginPath();

      if (rotation) {
        ctx.translate(center.x, center.y);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.translate(-center.x, -center.y);
      }

      const modifier = 2.5;

      // The length value represents the small sides of the invisible triangle.
      // This means the long line that creates the angle of the bracket is Math.sqrt(length^2 + length^2)
      ctx.moveTo(center.x - spacing - length * modifier, center.y - spacing + strokeWidth / 2);
      ctx.lineTo(center.x - spacing - length * modifier, center.y - spacing - length);
      ctx.lineTo(center.x - spacing - length, center.y - spacing - length * modifier);
      ctx.lineTo(center.x - spacing + strokeWidth / 2, center.y - spacing - length * modifier);

      ctx.strokeStyle = `rgba(${strokeColor._red}, ${strokeColor._green}, ${strokeColor._blue}, ${
        strokeAlpha / 100
      })`;
      ctx.lineWidth = width + strokeWidth;

      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }
  }

  function drawLine(startPoint, endPoint, rotationAngle) {
    ctx.save();
    ctx.beginPath();

    const midPoint = {
      x: center.x,
      y: center.y,
    };

    if (rotation) {
      ctx.translate(midPoint.x, midPoint.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-midPoint.x, -midPoint.y);
    }

    if (rotationAngle) {
      ctx.translate(midPoint.x, midPoint.y);
      ctx.rotate((rotationAngle * Math.PI) / 180);
      ctx.translate(-midPoint.x, -midPoint.y);
    }

    ctx.moveTo(startPoint.x, startPoint.y);
    ctx.lineTo(endPoint.x, endPoint.y);

    if (useGradient) {
      var grd = ctx.createLinearGradient(startPoint.x, startPoint.y, endPoint.x, endPoint.y);
      grd.addColorStop(
        0,
        `rgba(${gradientStartColor._red}, ${gradientStartColor._green}, ${
          gradientStartColor._blue
        }, ${gradientStartAlpha / 100}`
      );
      grd.addColorStop(
        1,
        `rgba(${gradientEndColor._red}, ${gradientEndColor._green}, ${gradientEndColor._blue}, ${
          gradientEndAlpha / 100
        })`
      );
      ctx.strokeStyle = grd;
    } else {
      ctx.strokeStyle = `rgba(${fillColor._red}, ${fillColor._green}, ${fillColor._blue}, ${
        fillAlpha / 100
      })`;
    }
    ctx.lineWidth = width;

    ctx.stroke();
    ctx.closePath();

    ctx.restore();
  }

  function drawLineStroke(startPoint, endPoint, rotationAngle) {
    ctx.save();
    ctx.beginPath();

    const midPoint = {
      x: center.x,
      y: center.y,
    };

    if (rotation) {
      ctx.translate(midPoint.x, midPoint.y);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-midPoint.x, -midPoint.y);
    }

    if (rotationAngle) {
      ctx.translate(midPoint.x, midPoint.y);
      ctx.rotate((rotationAngle * Math.PI) / 180);
      ctx.translate(-midPoint.x, -midPoint.y);
    }

    ctx.moveTo(startPoint.x, startPoint.y);
    ctx.lineTo(endPoint.x, endPoint.y);

    if (useStrokeGradient) {
      var grd = ctx.createLinearGradient(startPoint.x, startPoint.y, endPoint.x, endPoint.y);
      grd.addColorStop(
        0,
        `rgba(${strokeGradientStartColor._red}, ${strokeGradientStartColor._green}, ${
          strokeGradientStartColor._blue
        }, ${strokeGradientStartAlpha / 100}`
      );
      grd.addColorStop(
        1,
        `rgba(${strokeGradientEndColor._red}, ${strokeGradientEndColor._green}, ${
          strokeGradientEndColor._blue
        }, ${strokeGradientEndAlpha / 100})`
      );
      ctx.strokeStyle = grd;
    } else {
      ctx.strokeStyle = `rgba(${strokeColor._red}, ${strokeColor._green}, ${strokeColor._blue}, ${
        strokeAlpha / 100
      })`;
    }
    ctx.lineWidth = strokeWidth * 2 + width;

    ctx.stroke();
    ctx.closePath();

    ctx.restore();
  }
}

export default drawCrosshair;
