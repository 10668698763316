import React from "react";
import "./checkbox.scss";

function Checkbox(props) {
  let { name, value, classes } = props;

  value = Boolean(value);

  return (
    <input
      type="checkbox"
      checked={value}
      className={`checkbox ${classes ? classes : ""}`}
      name={name}
      onChange={(e) => {
        props.onChange([{ name, value: e.target.checked }]);
      }}
    />
  );
}

export default Checkbox;
