import React from "react";
import "./slider.scss";

function Slider(props) {
  const { name, value, min, max, step, classes } = props;

  return (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      className={`slider ${classes ? classes : ""}`}
      name={name}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        props.sliderUpdate([{ name, value }]);
      }}
    />
  );
}

export default Slider;
