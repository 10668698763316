import React from "react";

// Styles
import "./header.scss";
import "./navigation.scss";
import Kovaaks from "../../images/kovaaks_logo.svg";

function Header(props) {
  return (
    <header className="header">
      <div className="header__leftBlock">
        <a href="http://themeta.com">
          <img className="koovak__img" alt={`Koovak Home Logo`} src={Kovaaks} />
        </a>
        <h2 className="header__subtitle">Crosshair Generator</h2>
      </div>
     </header>
  );
}

export default Header;
