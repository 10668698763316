import React from "react";

// STYLES
import "./buttons.scss";

function LightButton(props) {
  const { additionalClasses, onClick, size, text, attributes } = props;
  return (
    <button
      {...attributes}
      className={`lightButton ${size ? size : "large"} ${additionalClasses}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

function DeleteButton(props) {
  const { onClick } = props;

  return (
    <button
      data-balloon-pos="up"
      className="deleteButton"
      aria-label="Delete Crosshair"
      onClick={onClick}
    >
      &#10006;
    </button>
  );
}

function ColorButton(props) {
  let {
    onClick,
    buttonActive,
    colors: { r, g, b, a },
  } = props;

  return (
    <div className="colorButton">
      <button className="colorButton__button" onClick={onClick}>
        {buttonActive ? "Close" : `Edit`}
      </button>
      <div
        className="colorButton__display"
        style={{
          backgroundColor: `rgba(${r},${g},${b},${a ? a / 100 : 0})`,
        }}
      ></div>
    </div>
  );
}

function LinkButton(props) {
  let { onClick, text } = props;

  return (
    <button className="linkButton" onClick={onClick}>
      {text}
    </button>
  );
}

export { LightButton, DeleteButton, ColorButton, LinkButton };
