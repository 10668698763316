import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/homepage";

import "./styles/app-baseline.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
