import React from "react";

// Styles
import "./footer.scss";

function Footer(props) {
  return (
    <footer className="footer">
      <div className="footer__content">
        <ul className="footer__list">
          <li className="footer__listItem">
            <a className="footer__link" href="https://store.steampowered.com/app/824270/KovaaK_20/">Get KovaaK's</a>
          </li>
          <li className="footer__listItem">
            <a className="footer__link" href="http://themeta.com">The Meta</a>
          </li>
          <li className="footer__listItem">
            <a className="footer__link" href="http://league.themeta.gg">The Meta League</a>
          </li>
          <li className="footer__listItem">
            <a className="footer__link" href="https://themeta.com/terms-of-service">Terms of Service</a>
          </li>
          <li className="footer__listItem">
            <a className="footer__link" href="https://themeta.com/eula">EULA</a>
          </li>
          <li className="footer__listItem">
            <a className="footer__link" href="https://themeta.com/privacy-policy">Privacy Policy</a>
          </li>
        </ul>
        <p className="legal">© The Meta Game, Inc. All rights reserved. The Meta, The Meta League and KovaaK's are trademarks and/or registered trademarks of The Meta Game, Inc.</p>
      </div>
     </footer>
  );
}

export default Footer;
