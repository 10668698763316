// EXTERNAL COMPONENTS
import React from "react";
import { CirclePicker } from "react-color";
import { DebounceInput } from "react-debounce-input";

// STYLES
import "./colors.scss";

function Colors(props) {
  const { colorObjectName, colors, crosshair, updateColor, defaultColors } = props;

  function getHexCode(colors) {
    let { r, g, b } = colors;

    if (r !== undefined && g !== undefined && b !== undefined)
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);

    function componentToHex(colorString) {
      var hex = colorString.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }
  }

  function handleHexCodeSubmit(crosshair, hexCode) {
    const { updateCrosshairObject } = props;
    const { id } = crosshair;

    let regexCheck;

    if (hexCode.length === 4) {
      regexCheck = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hexCode);
      if (regexCheck) {
        regexCheck[1] += 0;
        regexCheck[2] += 0;
        regexCheck[3] += 0;
      }
    } else {
      regexCheck = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
    }

    const dataArray = regexCheck
      ? [
          {
            name: colorObjectName,
            value: {
              _red: parseInt(regexCheck[1], 16),
              _green: parseInt(regexCheck[2], 16),
              _blue: parseInt(regexCheck[3], 16),
            },
          },
        ]
      : null;

    if (dataArray !== null) {
      updateCrosshairObject(id, dataArray);
    }
  }

  return (
    <React.Fragment>
      <label className="controlSection__group pickerBar">
        <CirclePicker
          width={"95%"}
          circleSize={20}
          color={colors}
          colors={defaultColors}
          onChangeComplete={(color) => updateColor(crosshair, color)}
        />
      </label>

      {/* HEX CODE FIELD */}

      <label className="controlSection__group">
        <p className="controlSection__label">Hex</p>
        <DebounceInput
          className="colors__hexCode"
          minLength={3}
          debounceTimeout={1000}
          type="text"
          value={getHexCode(colors)}
          onChange={(e) => handleHexCodeSubmit(crosshair, e.target.value)}
        />
      </label>

      {/* RGB FIELDS */}

      <div className="controlSection__group rgb">
        <p className="controlSection__label">RGB</p>
        <div className="colors__rgbGroup">
          {/* ===== RED ===== */}

          <label>
            <p className="controlSection__identifier">R</p>
            <DebounceInput
              name="red"
              className="controlSection__valueInput rgbNum"
              debounceTimeout={500}
              min="0"
              max="255"
              type="number"
              value={colors.r || "0"}
              onChange={(e) =>
                updateColor(crosshair, {
                  rgb: {
                    r: parseInt(e.target.value),
                    g: colors.g,
                    b: colors.b,
                  },
                })
              }
            />
          </label>

          {/* ===== GREEN ===== */}

          <label>
            <p className="controlSection__identifier">G</p>
            <DebounceInput
              name="green"
              className="controlSection__valueInput rgbNum"
              debounceTimeout={500}
              min="0"
              max="255"
              type="number"
              value={colors.g || "0"}
              onChange={(e) =>
                updateColor(crosshair, {
                  rgb: {
                    r: colors.r,
                    g: parseInt(e.target.value),
                    b: colors.b,
                  },
                })
              }
            />
          </label>

          {/* ===== BLUE ===== */}

          <label>
            <p className="controlSection__identifier">B</p>
            <DebounceInput
              name="blue"
              className="controlSection__valueInput rgbNum"
              debounceTimeout={500}
              min="0"
              max="255"
              type="number"
              value={colors.b || "0"}
              onChange={(e) =>
                updateColor(crosshair, {
                  rgb: {
                    r: colors.r,
                    g: colors.g,
                    b: parseInt(e.target.value),
                  },
                })
              }
            />
          </label>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Colors;
